import styles from './topSearches.module.css'
import {Podcast} from "./podcast/podcast";


export const TopSearches = () => {

    const urls = [
        "https://is4-ssl.mzstatic.com/image/thumb/Podcasts125/v4/14/99/f9/1499f976-736b-534f-f037-6d9fb5c97903/mza_11392786661966416284.jpg/313x0w.webp",
        "https://is4-ssl.mzstatic.com/image/thumb/Podcasts126/v4/c0/60/0e/c0600e3c-e37e-64e7-6124-ae4945ac0c90/mza_11503723001703077150.jpg/313x0w.webp",
        "https://is2-ssl.mzstatic.com/image/thumb/Podcasts125/v4/e8/9a/41/e89a4168-e25c-d020-efdf-0160bf149ac3/mza_4396725963959741020.jpeg/313x0w.webp"
    ]

    return <div className={styles.container}>
        <div className={styles.titleContainer}>
            <div className={styles.title}>
                Mest sökta poddar
            </div>
        </div>
        <div className={styles.podcastsContainer}>
            <Podcast place={1} imageUrl={urls[0]} title="Alex & Sigges Podcast" productionCompany="Perfect Day Media"/>
            <Podcast place={2} imageUrl={urls[1]} title="Ursäkta" productionCompany="Perfect Day Media"/>
            <Podcast place={3} imageUrl={urls[2]} title="Flashback Forever" productionCompany="Flashback Forever"/>
        </div>


    </div>

}