type props = {
    mt?: number,
    mb?: number,
    mr?: number,
    ml?: number,
    pt?: number,
    pb?: number,
    pr?: number,
    pl?: number,
}

export const Spacer = ({mt, mb, mr, ml, pt, pb, pr, pl}: props) => {

    return <div style={{
        marginTop: mt,
        marginLeft: ml,
        marginBottom: mb,
        marginRight: mr,
        paddingTop: pt,
        paddingLeft: pl,
        paddingBottom: pb,
        paddingRight: pr
    }}/>

}