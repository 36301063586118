import { useState, useEffect } from 'react';

export const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(false);
    const MOBILE_WIDTH = 480;

    useEffect(() => {
        calculateIsMobile()
    }, [])

    const calculateIsMobile = () => {
        if (window.outerWidth <= MOBILE_WIDTH) {
            setIsMobile(true)
            return
        }
        setIsMobile(false)
    }

    window.addEventListener('resize', () => {
        calculateIsMobile()
    });

    return isMobile;
}