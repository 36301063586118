import axios from "axios";
import {Podcast, PodcastsResponse} from "./podcasts.type";
import {Endpoint} from "../endpoint.enum";

export const getPodcasts = async (): Promise<Podcast[]> => {
    const url = Endpoint.BASE_URL + Endpoint.PODCASTS
    return axios.get(url)
        .then((response) => {
                const data: PodcastsResponse = response.data
                return data.podcasts
            }
        )
        .catch((e) => {
            console.log(e.response)
            return e.response
        })

}
