import styles from './episode.module.css'
import fontStyles from '../../../../shared/fonts/fonts.module.css'
import {EpisodeSearchResult as episodeType} from '../../../../shared/api/query/query.types'
import {Hit as HitType} from '../../../../shared/api/query/query.types'
import arrow from './assets/arrow.svg'
import {useEffect, useRef, useState} from "react";
import {Hit} from "./hit/hit";

type Props = {
    episode: episodeType,
    playEpisode: (timestampInSeconds: number, episode: episodeType) => void
    currentHitPlaying: { timestamp: number, episodeId: string }
    setCurrentHitPlaying: (timestamp: number, episodeId: string) => void
    tokens: string[]
}

export const Episode = ({episode, playEpisode, currentHitPlaying, setCurrentHitPlaying, tokens}: Props) => {


    const [isOpen, setIsOpen] = useState(false);
    const [pages, setPages] = useState<number[]>([])
    const [currentPage, setCurrentPage] = useState<number>(0)
    const [activeTokens, setActiveTokens] = useState<string[]>([])
    const numHitsPerPage = 5;
    const hits = episode.searchResults

    const [filteredHits, setFilteredHits] = useState<HitType[]>(hits)

    const hitsContainerRef = useRef<HTMLDivElement>(null)


    useEffect(() => {
        calculatePages()
        calculateCurrentPage()
    }, [filteredHits, hits])

    useEffect(() => {
        calculateHitsContainerHeight()
    }, [isOpen, filteredHits])

    const calculateCurrentPage = () => {
        if (currentPage * numHitsPerPage > filteredHits.length) {
            setCurrentPage(0)
        }
    }

    const calculateHitsContainerHeight = () => {
        if (hitsContainerRef.current) {
            if (pages.length < 2) {
                hitsContainerRef.current.style.height = "fit-content"
            }
        }
    }

    useEffect(() => {
        if (tokens.length == 1) {
            setActiveTokens(tokens)
        }
    }, [tokens])

    const filterOnToken = (token: string) => {
        if (tokens.length == 1) {
            return
        }

        let tempActiveTokens = activeTokens;


        if (activeTokens.includes(token)) {
            const tokenIndex = activeTokens.indexOf(token);
            tempActiveTokens.splice(tokenIndex, 1)
            setActiveTokens(tempActiveTokens)
        } else {
            tempActiveTokens.push(token)
            setActiveTokens(tempActiveTokens)
        }

        if (activeTokens.length == 0) {
            setFilteredHits(hits)
            return
        }

        const tempFilteredHits = hits.filter((hit) => {
            let hits = 0;
            for (const token of activeTokens) {
                for (const hitToken of hit.tokens) {
                    if (token == hitToken) {
                        hits += 1;
                    }
                }
            }
            return hits == activeTokens.length;
        })
        setFilteredHits(tempFilteredHits)

    }

    const calculatePages = () => {
        const numPages = (Math.ceil(filteredHits.length / numHitsPerPage))
        const tempPages: number[] = []
        for (let i = 0; i < numPages; i++) {
            tempPages.push(i)
        }
        setPages(tempPages)
    }


    const onPlay = (timestamp: number) => {
        playEpisode(timestamp, episode)
    }

    return <div className={styles.fullContainer}>
        <div id="episode" onClick={() => setIsOpen(!isOpen)} className={styles.container}>

            <div className={styles.imageContainer}>
                <img className={styles.image} alt="" src={episode.podcast.appleImageUrl + "100x100w.webp"}/>
            </div>

            <div className={styles.rightContainer}>
                <div className={styles.upperContainer}>
                    <div className={styles.title}>
                        {episode.title}
                    </div>

                    <div className={styles.numHits}>
                        <div className={styles.numHitsText}>
                            {episode.searchResults.length} {episode.searchResults.length === 1 ? "Träff" : "Träffar"}
                        </div>

                        <div className={styles.arrowContainer}>
                            {isOpen ?
                                <img className={`${styles.openedArrow} ${styles.arrowImage}`} alt="" src={arrow}/> :
                                <img className={styles.arrowImage} alt="" src={arrow}/>}
                        </div>
                    </div>

                </div>

                <div className={`${styles.lowerContainer} ${fontStyles.textButton}`}>
                    <div className={styles.podcastName}>
                        {episode.podcast.name}
                    </div>

                    <div className={styles.circle}/>

                    <div className={styles.publishDate}>
                        {episode.publishDate.toString()}
                    </div>

                    <div className={styles.circle}/>

                    <div className={styles.duration}>
                        {episode.duration}
                    </div>

                </div>
            </div>
        </div>

        {isOpen && <div className={styles.resultsContainer}>

            {tokens.length > 1 && <div className={styles.tokenFilterContainer}>
                {tokens.map((token, id) => {
                    if (activeTokens.includes(token)) {
                        return <div onClick={() => filterOnToken(token)} key={id}
                                    className={`${styles.tokenFilter} ${styles.tokenFilterActive}`}>
                            <div className={styles.tokenFilterText}>{token} </div>
                        </div>
                    } else {
                        return <div onClick={() => filterOnToken(token)} key={id} className={styles.tokenFilter}>
                            <div className={styles.tokenFilterText}>{token} </div>
                        </div>
                    }
                })}
            </div>}


            {pages.filter((page) => page == currentPage).map((page) => {
                return <div
                    ref={hitsContainerRef}
                    className={styles.hitsContainer}
                    key={page}> {filteredHits.slice(numHitsPerPage * (page), numHitsPerPage * (page + 1)).map((hit, id) => {
                    return <div key={id}>
                        <Hit episodeId={episode.id} currentHitPlaying={currentHitPlaying}
                             setCurrentHitPlaying={setCurrentHitPlaying} tokens={hit.tokens} onPlay={onPlay}
                             timestampInSeconds={hit.timestamp}/>
                    </div>
                })}
                </div>

            })}

            {pages.length > 1 && <div className={styles.pageNumberContainer}>{pages.map((pageNumber) => {
                return <div key={pageNumber}>
                    {pageNumber == currentPage ?
                        <div className={`${styles.pageNumber} ${styles.pageNumberActive}`}
                             onClick={() => setCurrentPage(pageNumber)}
                             key={pageNumber}>{pageNumber + 1}</div> :
                        <div className={styles.pageNumber} onClick={() => setCurrentPage(pageNumber)}
                             key={pageNumber}>{pageNumber + 1}</div>}
                </div>
            })}</div>}
        </div>
        }


    </div>

}