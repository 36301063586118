import React, {useEffect, useRef, useState} from "react";
import styles from './search.module.css'
import footerStyles from '../../shared/components/footer/footer.module.css'
import {Header} from "../../shared/components/header/header";
import {Spacer} from "../../shared/components/common/spacer/spacer";
import {useSearchParams} from "react-router-dom";
import {LoaderAnimation} from "../../shared/components/loaderAnimation/loaderAnimation";
import {Footer} from "../../shared/components/footer/footer";
import {SearchResults} from "./searchResults/searchResults";
import {Player} from "../../shared/components/player/player";
import {query} from '../../shared/api/query/query'
import {UrlParam} from "../../shared/enum/urlParam/urlParam.enum";
import {TimestampAlignResponse} from "../../shared/api/alignTimestamp/timestampAlignment.type";
import {alignTimestamp} from "../../shared/api/alignTimestamp/alignTimestamp";
import {BackendResponse, EpisodeSearchResult} from "../../shared/api/query/query.types";
import {useIsMobile} from "../../shared/hooks/useIsMobile";
import {SortOrder} from "../../shared/enum/sort/sort.enum";
import {SortFilterContainer} from "./searchResults/sortFilterContainer/sortFilterContainer";
import {SearchResultsInfoNoHits} from "./searchResultsInfoNoHits/searchResultsInfoNoHits";
import {BookBeatBanner} from "../../shared/components/ads/bookBeatBanner/bookBeatBanner";

export const Search = () => {

    const [searchParams] = useSearchParams();
    const [episodes, setEpisodes]: any = useState<EpisodeSearchResult[]>();

    const [currentQuery, setCurrentQuery]: any = useState<BackendResponse>()
    const [currentEpisode, setCurrentEpisode]: any = useState<EpisodeSearchResult>();
    const [currentQueryOffset, setCurrentQueryOffset]: any = useState<number>(0);
    const [timestampInSecondsToPlayFrom, setTimestampInSecondsToPlayFrom] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isResultsEmpty, setIsResultsEmpty]: any = useState()
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [isPlayerLoading, setIsPlayerLoading] = useState(false);
    const [showResultsContainer, setShowResultsContainer] = useState(true);

    const sortContainerRef = useRef<HTMLDivElement>(null);

    const [queryString, setQueryString]: any = useState()
    const playOffsetInSeconds = 13;

    const [podcastFilterIds, setPodcastFilterIds] = useState<string[]>([]);
    const [yearsToFilterOn, setYearsToFilterOn] = useState<number[]>([]);
    const [monthsToFilterOn, setMonthsToFilterOn] = useState<number[]>([]);
    const [dateFilter, setDateFilter] = useState<string>("")
    const [sortOrder, setSortOrder] = useState<string>(SortOrder.RELEVANCE)
    const [isNewSearchInProgress, setIsNewSearchInProgress] = useState<boolean>(false);

    const isMobile = useIsMobile()

    useEffect(() => {
        setIsLoaded(false);
        executeQuery(true)
    }, [queryString])


    useEffect(() => {
        calculateMarginToHeader()
    }, [podcastFilterIds])


    const calculateMarginToHeader = () => {
        if (sortContainerRef.current && !isMobile) {
            if (podcastFilterIds.length > 0) {
                const currentMarginTop = parseFloat(getComputedStyle(sortContainerRef.current).getPropertyValue('margin-top'));
                if (parseFloat(getComputedStyle(sortContainerRef.current).getPropertyValue('--margin-top')) == currentMarginTop) {
                    sortContainerRef.current.style.marginTop = currentMarginTop + 30 + "px";
                }
            } else {
                sortContainerRef.current.style.marginTop = getComputedStyle(sortContainerRef.current).getPropertyValue('--margin-top');
            }
        }
    }

    useEffect(() => {
        setIsLoaded(false);
        executeQuery(false)
    }, [podcastFilterIds, yearsToFilterOn, monthsToFilterOn, sortOrder])

    const executeQuery = (isNewQuery: boolean) => {
        if (isNewQuery) {
            setIsNewSearchInProgress(true)
        }
        if (queryString) {
            setCurrentQueryOffset(0)
            setEpisodes([])
            query(queryString, sortOrder, podcastFilterIds, dateFilter, yearsToFilterOn, monthsToFilterOn, 0).then((res) => {

                if (!res?.episodes) {
                    setIsResultsEmpty(true);
                    setIsLoaded(true);
                    setCurrentQuery(null);
                    return
                }
                setIsResultsEmpty(false);

                if (currentQuery && currentQuery.query == res.query && currentQuery.offset != res.offset) {
                    res.episodes = currentQuery.episodes.concat(res.episodes)
                } else {
                    if (!currentEpisode) {
                        setCurrentEpisode(res.episodes[0])
                    }
                }
                setCurrentQuery(res)

                setEpisodes(res.episodes)
                setIsLoaded(true);
                setIsNewSearchInProgress(false);
            }).catch((e: any) => {
                // console.log(e);
                setIsResultsEmpty(true);
                setIsLoaded(true);
                setIsNewSearchInProgress(false);
            })
        }
    }


    useEffect(() => {
        if (!queryString) return;
        if (!currentQuery) return;
        if (currentQuery.offset == currentQueryOffset) return;
        if (currentQueryOffset === 0) return;
        query(queryString, sortOrder, podcastFilterIds, dateFilter, yearsToFilterOn, monthsToFilterOn, currentQueryOffset).then((res) => {

            if (!res?.episodes) {
                setCurrentQuery(null);
                setIsLoadingMore(false)
                return
            }
            res.episodes = currentQuery.episodes.concat(res.episodes)
            setCurrentQuery(res)
            setEpisodes(res.episodes)
            setIsLoadingMore(false)
        }).catch((e: any) => {
            setIsLoadingMore(false);
        })

    }, [currentQueryOffset])


    useEffect(() => {
        setQueryString(searchParams.get(UrlParam.QUERY_STRING))

        const podcastFilterValues = searchParams.get(UrlParam.FILTER_PODCAST_IDS)
        const yearsFilterValues = searchParams.get(UrlParam.FILTER_DATE_YEAR)
        const monthsFilterValues = searchParams.get(UrlParam.FILTER_DATE_MONTH)
        const sortOrder = searchParams.get(UrlParam.SORT_BY);
        const filter_on_date = searchParams.get(UrlParam.FILTER_DATE);

        podcastFilterValues ? setPodcastFilterIds(JSON.parse(podcastFilterValues)) : setPodcastFilterIds([])
        yearsFilterValues ? setYearsToFilterOn(JSON.parse(yearsFilterValues)) : setYearsToFilterOn([])
        monthsFilterValues ? setMonthsToFilterOn(JSON.parse(monthsFilterValues)) : setMonthsToFilterOn([])
        filter_on_date ? setDateFilter(filter_on_date) : setDateFilter("")

        if (sortOrder && Object.values(SortOrder).includes(sortOrder as SortOrder)) {
            //console.log(sortOrder)
            setSortOrder(sortOrder)
        }


        //console.log(`NewFilters: podcasts: ${podcastFilterValues}, years: ${yearsFilterValues}, months: ${monthsFilterValues}`)
    }, [searchParams])


    useEffect(() => {
        window.addEventListener("scroll", handleScroll)

        return function cleanup() {
            window.removeEventListener("scroll", handleScroll)
        }
    });


    const playEpisode = async (timestamp: number, episode: EpisodeSearchResult) => {
        setIsPlayerLoading(true);
        setCurrentEpisode(episode);
        const timestampAlignResponse: TimestampAlignResponse = await alignTimestamp(timestamp, episode)
        episode.mp3Url = timestampAlignResponse.url
        const newTimestamp = timestampAlignResponse.timestamp
        // console.log(`Original offset: ${timestamp}, new offset: ${newTimestamp}`);
        setTimestampInSecondsToPlayFrom(newTimestamp);
        setIsPlayerLoading(false);
    }
    const handleScroll = (e: any) => {

        if (currentQuery?.episodes.length < 25 || !currentQuery?.episodes) {
            window.removeEventListener("scroll", handleScroll)
            return;
        }

        const footers = document.getElementsByClassName(footerStyles.footer)

        if (!showResultsContainer) return;
        if (!footers || footers.length == 0) return;
        if (!isLoaded || isResultsEmpty) return;
        if (isLoadingMore) return;

        const footer = footers[0]
        if (footer.getBoundingClientRect().y - window.innerHeight > 0) return;
        setIsLoadingMore(true);
        setCurrentQueryOffset(currentQueryOffset + 25)
    }

    const handlePopupOpenOrClose = (opened: boolean) => {
        if (isMobile) {
            const body = document.getElementById("body")
            if (opened) {
                if (body) {
                    body.style.overflow = "hidden"
                }
            } else {
                if (body) {
                    body.style.overflow = "auto"
                }
            }
            setShowResultsContainer(!opened)
        }
    }

    useEffect(() => {
        if (isLoaded) {
            setShowResultsContainer(true)
        }
    }, [isLoaded])

    return <div onScroll={handleScroll}>
        <Header showBlogNavItem={false} showSearchBar onPopupOpen={() => handlePopupOpenOrClose(true)}
                onPopupClose={() => handlePopupOpenOrClose(false)} showSortContainer={isMobile} centerLogo={isMobile} showBottomBar={isMobile}/>

        {/*<div ref={adContainerRef} className={styles.bookBeatBannerContainer}>*/}
        {/*    <div className={styles.bookBeatBanner}>*/}
        {/*        <BookBeatBanner/>*/}
        {/*    </div>*/}
        {/*</div>*/}


        {!isNewSearchInProgress && <div className={styles.divider}>
            {/*<SearchResultsInfo query={queryString}*/}
            {/*                   numHits={currentQuery?.numMatchedEpisodes}/>*/}
            <div className={styles.sortFilterContainer} ref={sortContainerRef}><SortFilterContainer/></div>
        </div>}


        {isLoaded ? isResultsEmpty ?
                <div>
                    <div className={styles.noResultsContainer}><SearchResultsInfoNoHits query={queryString}
                                                                                        numHits={currentQuery?.numMatchedEpisodes}
                                                                                        noHits/></div>
                </div>
                :
                <div>
                    <div className={styles.resultsContainer}>

                        <SearchResults playEpisode={playEpisode} results={episodes}/>
                    </div>
                </div>
            :
            <div>
                <div className={styles.mainLoadingContainer}>
                    <LoaderAnimation/>
                </div>

            </div>}

        {isLoadingMore &&
        <div className={styles.lowerLoadingContainer}>
            <LoaderAnimation/>
        </div>
        }
        {currentEpisode && <Player playOffsetInSeconds={playOffsetInSeconds}
                                   timestampInSecondsToPlayFrom={timestampInSecondsToPlayFrom}
                                   mp3Url={currentEpisode?.mp3Url}
                                   imageUrl={currentEpisode?.podcast?.appleImageUrl+"800x800w.webp"}
                                   episodeTitle={currentEpisode?.title} podcastName={currentEpisode?.podcast?.name}
                                   forceLoading={isPlayerLoading}
                                   onLargePlayerOpen={() => handlePopupOpenOrClose(true)}
                                   onLargePlayerClose={() => {
                                       handlePopupOpenOrClose(false)
                                   }}/>}

        <Footer/>
        <Spacer mt={100}/>

    </div>

}
