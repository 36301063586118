import styles from './infoContainer.module.css'
import {TextContainer} from "./textContainer/textContainer";
import {ImageContainer} from "./imageContainer/imageContainer";

type Props = {
    title: string
    body: string
    imageUrl: string
    reversed?: boolean
}

export const InfoContainer = ({title, body, imageUrl, reversed = false}: Props) => {

    return <div className={styles.container}>

        {reversed ? <ImageContainer reversed={reversed} imageUrl={imageUrl}/> :
            <TextContainer reversed={reversed} title={title} body={body}/>}

        <div className={styles.rightContainer}>
            {reversed ? <TextContainer reversed={reversed} title={title} body={body}/> :
                <ImageContainer reversed={reversed} imageUrl={imageUrl}/>}
        </div>

    </div>

}