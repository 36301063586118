import styles from './searchPopup.module.css'
import {PopupSearchBar} from "../popupSearchBar/popupSearchBar";
import {useSearchParams} from "react-router-dom";
import {UrlParam} from "../../enum/urlParam/urlParam.enum";
import {useEffect} from "react";

type Props = {
    onSearch: (query: string) => void
    backButtonPressed: () => void
}

export const SearchPopup = ({onSearch, backButtonPressed}: Props) => {

    const [searchParams, _] = useSearchParams();

    useEffect(() => {

    }, [])

    return <div className={styles.container}>

            <PopupSearchBar preFilledValue={searchParams.get(UrlParam.QUERY_STRING)} placeholderValue="Sök efter ämne, citat, omnämnande..." onEnterPress={onSearch} backButtonPressed={backButtonPressed}/>

    </div>

}