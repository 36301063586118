import { useState, useEffect } from 'react';

export const UseWindowWidth = () => {
    const [width, setWidth] = useState(window.outerWidth);

    useEffect(() => {
        calculateWidth()
    }, [])

    const calculateWidth = () => {
        setWidth(window.outerWidth)
    }

    window.addEventListener('resize', () => {
        calculateWidth()
    });

    return width;
}