import styles from './navItem.module.css'

type props = {
    href: string,
    title: string,
    isActive?: boolean
}


export const NavItem = ({href, title, isActive = false}: props) => {
    return <div className={styles.container}>
        {isActive ?
            <div >
                <div className={styles.activeContainer}>
                    <a href={href} className={`${styles.navItem} ${styles.active}`}>{title}</a>
                </div>
                <div className={styles.activeDivider}> </div>
            </div>

            :

            <a href={href} className={styles.navItem}>{title}</a>
        }
    </div>
}