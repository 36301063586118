import styles from './blogPost.module.css'

type Props = {
    imageUrl: string
    title: string,
    body: string
    sections?: { subTitle: string, body: string, buttons?: Button[] }[]
    bottomButton?: Button
}

type Button = {
    title: string,
    href: string
}

export const BlogPost = ({imageUrl, title, body, sections, bottomButton}: Props) => {

    return <div className={styles.container}>

        <div className={styles.headerImageContainer}>
            <img src={imageUrl} className={styles.headerImage} alt=""/>
        </div>

        <div className={styles.titleContainer}>
            <div className={styles.title}>
                {title}
            </div>
        </div>

        <div className={styles.bodyContainer}>
            <div className={styles.body}>
                {body}
            </div>
        </div>

        {sections?.map((section) => {
            return <div className={styles.section}>

                <div className={styles.subTitleContainer}>
                    <div className={styles.subTitle}>
                        {section.subTitle}
                    </div>
                </div>

                <div className={styles.bodyContainer}>
                    <div className={styles.body}>
                        {section.body}
                    </div>
                </div>

                {section.buttons &&
                <div className={styles.buttonsContainer}>
                    {section.buttons.map((buttonProps) => {
                        return <a href={buttonProps.href} className={styles.sectionButton}>
                            <div className={styles.buttonTextContainer}>
                                {buttonProps.title}
                            </div>
                        </a>
                    })}
                </div>
                }


            </div>

        })}
        {bottomButton &&
        <div className={styles.bottomButtonContainer}>
            <a href={bottomButton.href} className={styles.bottomButton}>
                <div className={styles.buttonTextContainer}>
                    {bottomButton.title}
                </div>
            </a>
        </div>
        }

    </div>

}