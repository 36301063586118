import styles from "./timeContainer.module.css";
import {useEffect, useRef, useState} from "react";
import {fromSecondsToHoursMinutesSeconds} from "../../../utils/utils";
import {useIsMobile} from "../../../hooks/useIsMobile";
import {UseWindowWidth} from "../../../hooks/useWindowWidth";

export type Props = {
    changeTime: (volume: number) => void,
    duration: number,
    timeElapsed: number,
    timeRemaining: number | null
    isLoading: boolean
    isInLargePlayer?: boolean
}

export const TimeContainer = ({
                                  changeTime,
                                  duration,
                                  timeElapsed,
                                  timeRemaining,
                                  isLoading,
                                  isInLargePlayer
                              }: Props) => {

    const timeBarRef = useRef<HTMLInputElement>(null);
    const timeElapsedOnHoverRef = useRef<HTMLDivElement>(null);
    const timeTextsRef = useRef<HTMLDivElement>(null);
    const timeContainerRef = useRef<HTMLDivElement>(null);
    const [showHoverContainer, setShowHoverContainer] = useState<boolean>(false)

    const isMobile = useIsMobile();
    const windowWidth = UseWindowWidth();

    useEffect(() => {
        if (timeElapsedOnHoverRef.current) {
            if (!isInLargePlayer && isMobile) {
                return
            }
            showHoverContainer ? timeElapsedOnHoverRef.current.style.display = "flex" : timeElapsedOnHoverRef.current.style.display = "none"
        }
    }, [showHoverContainer])

    useEffect(() => {
        if (isInLargePlayer) {
            if (timeTextsRef.current && timeContainerRef.current) {
                timeTextsRef.current.style.position = "sticky"
                timeTextsRef.current.style.marginBottom = "-10px"

                timeContainerRef.current.style.width = "323px"
                timeContainerRef.current.style.height = "40px"
            }
        }
    }, [timeElapsed])

    useEffect(() => {
        if (isMobile && timeBarRef.current) {
            if (isLoading) {
                timeBarRef.current.style.setProperty("--timeBarThumbVisible", "hidden")
                return
            }

            if (!isInLargePlayer){
                timeBarRef.current.style.setProperty("--timeBarThumbVisible", "hidden")
                return
            }

            timeBarRef.current.style.setProperty("--timeBarThumbVisible", "visible")
            return

        }
    }, [isLoading, isInLargePlayer, timeRemaining])

    useEffect(() => {
            if (timeBarRef.current) {
                if (isLoading) {
                    timeBarRef.current.style.setProperty('--seek-before-width', `0px`)
                    return
                }

                if (timeElapsed && timeContainerRef.current) {
                    let timeBarWidth;
                    timeBarWidth = parseFloat(getComputedStyle(timeBarRef.current).getPropertyValue('width'));
                    const progressWidth = timeElapsed / duration * timeBarWidth;
                    timeBarRef.current.value = String(timeElapsed);
                    timeBarRef.current.style.setProperty('--seek-before-width', `${progressWidth}px`)

                    if (timeElapsedOnHoverRef.current) {
                        timeElapsedOnHoverRef.current.style.left = `${(windowWidth / 2) - timeBarWidth / 2 + progressWidth - 20}px`
                    }
                }

            }
        }, [timeElapsed, duration, isLoading, windowWidth]
    )

    return <div ref={timeContainerRef} className={styles.timeContainer}>

        <div ref={timeTextsRef} className={styles.timeTextsContainer}>

            <div className={styles.timeElapsed}>
                {!isLoading ? fromSecondsToHoursMinutesSeconds(timeElapsed) : ""}
            </div>

            <div className={styles.timeRemaining}>
                {(!isLoading && timeRemaining) ? "-" + fromSecondsToHoursMinutesSeconds(timeRemaining) : ""}
            </div>
        </div>

        <div ref={timeElapsedOnHoverRef} className={styles.timeElapsedOnHoverContainer}>
            <div className={styles.timeElapsedOnHover}>
                {!isLoading ? fromSecondsToHoursMinutesSeconds(timeElapsed) : ""}
            </div>

        </div>

        <div className={styles.timeBarContainer}>
            <input type="range" defaultValue={0} ref={timeBarRef} min={0} max={duration} step={1}
                   onChange={(event) => {
                       if (isMobile && !isInLargePlayer) {
                           return
                       }
                       changeTime(parseFloat(event.currentTarget.value));
                   }}
                   onMouseDown={() => setShowHoverContainer(true)}
                   onMouseUp={() => setShowHoverContainer(false)}
                   className={styles.timeBar}/>
        </div>

    </div>

}