import {Logo} from "../logo/logo";
import styles from './header.module.css'
import {useLocation} from "react-router-dom";
import {NavItem} from "./navItem/navItem";
import {SearchContainer} from "../searchContainer/searchContainer";
import {useEffect, useRef} from "react";
import {useIsMobile} from "../../hooks/useIsMobile";
import searchButtonImage from './assets/orangeSearchIcon.svg'

type Props = {
    showSortContainer?: boolean
    onPopupOpen?: () => void
    onPopupClose?: () => void
    showSearchBar?: boolean
    showBlogNavItem?: boolean
    showSearchButton?: boolean
    centerLogo?: boolean
    showBottomBar?: boolean
}

export const Header = ({onPopupOpen, onPopupClose, showSearchBar = false, showBlogNavItem = true, showSearchButton = false, centerLogo = false, showBottomBar = false}: Props) => {

    const location = useLocation()
    const webHeaderDividerRef = useRef<HTMLDivElement>(null)
    const logoContainerRef = useRef<HTMLDivElement>(null)
    const headerContainerRef = useRef<HTMLDivElement>(null)
    const searchBarRef = useRef<HTMLDivElement>(null)

    const isMobile = useIsMobile();

    useEffect(() => {
        if (isMobile && !showSearchBar && logoContainerRef.current && headerContainerRef.current) {
            headerContainerRef.current.style.paddingBottom = "12px";
            headerContainerRef.current.style.paddingTop = "4px  ";
            logoContainerRef.current.style.marginBottom = "0";
            logoContainerRef.current.style.marginTop = "0";
        }
        if (isMobile && logoContainerRef.current && centerLogo) {
            logoContainerRef.current.style.margin = "auto";
            logoContainerRef.current.style.marginBottom = "12px";
        }

        if (isMobile && headerContainerRef.current && showBottomBar) {
            headerContainerRef.current.style.borderBottom = "0.5px solid rgba(194, 198, 204, 0.5)";
        }

    }, )



    const onSearchButtonClick = () => {
        document.getElementById("openSearchBar")?.click()
    }

    return <div ref={headerContainerRef} className={styles.container}>

        <div ref={logoContainerRef} className={styles.logoContainer}>
            <Logo/>
        </div>

        <div ref={searchBarRef} className={styles.searchBarContainer}>
            <SearchContainer onPopupOpen={onPopupOpen} onPopupClose={onPopupClose}
                             headerDividerRef={webHeaderDividerRef} showSearchBar={showSearchBar}/></div>


        {showSearchButton && <div onClick={onSearchButtonClick} className={styles.searchButtonContainer}>
            <button  className={styles.searchButton}>
                <img src={searchButtonImage} alt=""/>
            </button>
        </div>}

        <div className={styles.rightContainer}>
            {showBlogNavItem && <NavItem href="/blog" isActive={location.pathname === "/blog"} title="Blogg"/>}
            <NavItem href="/about" isActive={location.pathname === "/about"} title="Om oss"/>

        </div>

        {showSearchBar && <div ref={webHeaderDividerRef} className={styles.divider}/>}

    </div>

}