import styles from './collaborations.module.css'
import AoS from './assets/A&S.png'
import innovationLogo from './assets/innovationLogo.png'
import perfectDayLogo from './assets/perfect-day-media-grey.png'


export const Collaborations = () => {

    return <div className={styles.container}>

        <div className={styles.titleContainer}>
            <div className={styles.title}>
                Samarbeten
            </div>

        </div>

        <div className={styles.collaborationsContainer}>

            <div className={styles.imageContainer}>
                <img className={styles.innovationLogo} src={innovationLogo} alt={""}/>
            </div>

            <div className={styles.imageContainer}>
                <img className={styles.pdLogo} src={perfectDayLogo} alt={""}/>
            </div>


            <div className={styles.imageContainer}>
                <img className={styles.aosLogo} src={AoS} alt={""}/>
            </div>

        </div>

    </div>

}