import styles from './sortButton.module.css'
import {Popup} from "../popup/popup";
import React, {useState} from "react";
import sortImage from "./assets/sortImage.svg";
import {SortOrder} from "../../../../../shared/enum/sort/sort.enum";


type Props = {
    values: { value: string, name: string }[]
    onSortValueClick: (value: string) => void
    currentValue: { value: SortOrder.LATEST | SortOrder.NAME_ASCENDING | SortOrder.RELEVANCE, name: string }
}

export const SortButton = ({values, onSortValueClick, currentValue}: Props) => {
      const [showSortPopup, setShowSortPopup] = useState<boolean>(false);

      const handleSortValueClick = (sortValue: string) => {
          onSortValueClick(sortValue)
          setShowSortPopup(false);
      }

    return <div className={styles.container}>
        <button id="sort-button" onClick={() => setShowSortPopup(!showSortPopup)} className={styles.button}>
            <div className={styles.sortImageContainer}>
                {showSortPopup ? <img className={styles.sortImageOpened} src={sortImage}/> :
                    <img className={styles.sortImage} src={sortImage}/>}
            </div>

            <div className={styles.titleContainer}>
                {currentValue.name}
            </div>
        </button>
        {showSortPopup && <Popup width={128} onValueClick={handleSortValueClick} currentValue={currentValue.value} values={values}/>}
    </div>

}