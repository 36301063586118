import styles from "./volumeContainer.module.css";
import {useEffect, useRef} from "react";

type Props = {
    setVolume: (volume: number) => void
}

export const VolumeContainer = ({setVolume}: Props) => {

    const volumeBar: any = useRef()

    const changeVolume = (volume: number) => {
        const volumeWidth = parseFloat(getComputedStyle(volumeBar.current).getPropertyValue('--width'));
        volumeBar.current.style.setProperty('--seek-before-width', `${volume * volumeWidth}px`);
        setVolume(volume);
        try {
            localStorage.setItem("playerVolume", volume.toString());
        } catch (e) {
            console.log(e)
            return;
        }
    }

    useEffect(() => {
        try {
            const volumeLocalStorage = localStorage.getItem("playerVolume")
            if (volumeLocalStorage) {
                changeVolume(parseFloat(volumeLocalStorage))
            }
        } catch (e) {
            console.log(e)
            return
        }
    }, [])

    return <div className={styles.volumeContainer}>

        <input onChange={(event) => changeVolume(parseFloat(event.target.value))}
               type="range" min={0} max={1} step={0.01} className={styles.volumeBar} ref={volumeBar}/>

    </div>
}