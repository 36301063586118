import styles from "./searchBar.module.css"
import searchImageWeb from './assets/searchIconWeb.svg';
import searchImageGrey from './assets/search-24-grey.svg';
import fontStyles from "../../../../shared/fonts/fonts.module.css"
import {useLoaderData} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {useIsMobile} from "../../../hooks/useIsMobile";
import {PodcastFilter} from "../../../api/filters/filters.type";
import {FilterButton} from "./filterButton/filterButton";
import {FilterPopup} from "../../filterPopup/filterPopup";

type Props = {
    currentRef?: React.ForwardedRef<HTMLDivElement>
    isFiltered: boolean
    handleShowSearchPop: () => void
    filterPopButtonPressed: () => void
    initiateQuery: (val: string) => void
    showFilterPopup: boolean
    filterPopupBackButtonPressed: () => void
    podcastFilters: PodcastFilter[]
}

export const SearchBar = ({
                              currentRef = null,
                              isFiltered,
                              handleShowSearchPop,
                              filterPopButtonPressed,
                              initiateQuery,
                              showFilterPopup,
                              filterPopupBackButtonPressed,
                              podcastFilters
                          }: Props) => {

    const q: any = useLoaderData();
    const searchRef = useRef<HTMLInputElement>(null)
    const isMobile = useIsMobile()
    const [placeholderText, setPlaceholderText] = useState<string>("Sök på ämne, citat, omnämnande...")

    const handleSubmit = () => {
        if (searchRef.current) {
            initiateQuery(searchRef.current.value)
        }
    }

    useEffect(() => {
        if (searchRef.current && q) {
            searchRef.current.value = q
        }
    }, [q]);

    useEffect(() => {
        if (isMobile) {
            setPlaceholderText("Sök på ämne, omnämnande...")
        }
    }, [isMobile])




    return (
        <div ref={currentRef} className={styles.searchBar}>

            <div onClick={handleShowSearchPop} className={styles.leftContainer}>
                {isMobile ? <img src={searchImageGrey} className={styles.searchImage} alt=""/> :
                    <img src={searchImageWeb} className={styles.searchImage} alt=""/>}

                <input
                    ref={searchRef}
                    enterKeyHint="search"
                    onKeyPress={(event) => {
                        if (event.key === "Enter" && searchRef.current?.value != "") {
                            handleSubmit()
                        }
                    }}
                    defaultValue={q} type="text" placeholder={placeholderText}
                    className={`${styles.input} ${fontStyles.bodySmall}`}/>
            </div>

            <div className={styles.rightContainer}>

                <FilterButton isActive={isFiltered} filterPopButtonPressed={filterPopButtonPressed}/>

                {showFilterPopup &&
                <FilterPopup podcastFilters={podcastFilters} onClose={filterPopupBackButtonPressed}/>}

            </div>

        </div>
    )

}