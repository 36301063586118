import styles from './largePlayer.module.css'
import closeButton from './assets/closeButton.svg'
import {ButtonContainer} from "../buttonContainer/buttonContainer";
import {TimeContainer} from "../timeContainer/timeContainer";
import {Props as TimeContainerProps} from '../timeContainer/timeContainer'
import {Props as ButtonContainerProps} from '../buttonContainer/buttonContainer'


type Props = TimeContainerProps & ButtonContainerProps & {
    imageUrl: string,
    episodeTitle: string,
    podcastName: string,
    onClose: () => void
}


export const LargePlayer = ({
                                onClose,
                                imageUrl,
                                episodeTitle,
                                podcastName,
                                changeTime,
                                duration,
                                timeElapsed,
                                timeRemaining,
                                isLoading,
                                isPlay,
                                backwardClick,
                                forwardClick,
                                setIsPlay
                            }: Props) => {


    return <div id="large-player" className={styles.container}>

        <div className={styles.closeButtonContainer}>
            <button onClick={onClose} className={styles.closeButton}>
                <img className={styles.closeButtonImage} src={closeButton} alt="close"/>
            </button>
        </div>

        <div className={styles.episodeImageContainer}>
            <img src={imageUrl} className={styles.episodeImageContainer}/>
        </div>

        <div className={styles.episodeTitleContainer}>
            <div className={styles.episodeTitle}>
                {episodeTitle}
            </div>
        </div>

        <div className={styles.podcastNameContainer}>
            <div className={styles.podcastName}>
                {podcastName}
            </div>
        </div>

        <ButtonContainer backwardClick={backwardClick} forwardClick={forwardClick} setIsPlay={setIsPlay} isPlay={isPlay}
                         isLoading={isLoading}
                         isInLargePlayer/>

        <div className={styles.timeContainerContainer}>
            <TimeContainer changeTime={changeTime} duration={duration} timeElapsed={timeElapsed}
                           timeRemaining={timeRemaining} isLoading={isLoading} isInLargePlayer/>
        </div>

    </div>

}