import styles from './searchButton.module.css'
import searchImage from './assets/search-24.png'
import React from "react";

type Props = {}

export const SearchButtonV2 = ({}: Props) => {

    const onSearchButtonClick = () => {
        document.getElementById("openSearchBar")?.click()
    }

    return <button id="home-page-search-button-orange" onClick={onSearchButtonClick} className={styles.container}>
            <img src={searchImage} className={styles.searchImage} alt=""/>
            <div
                className={styles.text}>
                Skriv in ett sökord
            </div>
    </button>
}