import styles from './filterButton.module.css'
import {Popup} from "../popup/popup";
import React, {useState} from "react";
import {Filter} from "../../../../../shared/enum/filter/filter.enum";


type Props = {
    values: { value: string, name: string }[]
    onFilterValueClick: (filterValue: string) => void
    currentValue: { value: Filter.LAST_MONTH | Filter.NO_FILTER | Filter.LAST_WEEK | Filter.LAST_YEAR, name: string }
}


export const FilterButton = ({values, onFilterValueClick, currentValue}: Props) => {

    const [showFilterPopup, setShowFilterPopup] = useState<boolean>(false);

    const handleFilterValueClick = (filterValue: string) => {
        onFilterValueClick(filterValue)
        setShowFilterPopup(false);
    }

    return <div className={styles.container}>
        <button id="filter-by-time-button" onClick={() => setShowFilterPopup(!showFilterPopup)} className={styles.button}>
            <div className={styles.titleContainer}>
                {currentValue.name}
            </div>
            {showFilterPopup ? <div className={`${styles.arrow} ${styles.openedArrow}`}/> :
                <div className={styles.arrow}/>}

        </button>

        {showFilterPopup && <Popup onValueClick={handleFilterValueClick} currentValue={currentValue.value} values={values}/>}
    </div>

}