import {EpisodeSearchResult} from "../query/query.types";
import {TimestampAlignResponse} from "./timestampAlignment.type";
import {Endpoint} from "./endpoint.enum"

export const alignTimestamp = async (timestamp: number, episode: EpisodeSearchResult): Promise<TimestampAlignResponse> => {

    let mp3Url: string = episode.mp3Url
    let newTimestamp: number = timestamp

    try {

        const alignmentRequestUrl: string = Endpoint.BASE_URL + Endpoint.STAGE + Endpoint.METHOD
        const alignmentResponse = await fetch(alignmentRequestUrl, {
            method: 'POST',
            body: JSON.stringify({
                "episodeId": episode.id,
                "mp3Url": mp3Url,
                "timestamp": timestamp
            })
        });
        const respJson = await alignmentResponse.json()
        newTimestamp = respJson.timestamp
        if (respJson.mp3Url && respJson.mp3Url != "") {
            mp3Url = respJson.mp3Url
        }
    } catch (e) {

    }

    return {timestamp: newTimestamp, url: mp3Url}
}
