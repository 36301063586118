import styles from './player.module.css'
import {useEffect, useRef, useState} from "react";
import {VolumeContainer} from "./volumeContainer/volumeContainer";
import {TimeContainer} from "./timeContainer/timeContainer";
import {EpisodeDataContainer} from "./episodeDataContainer/episodeDataContainer";
import tempSilentSound from './assets/tempSilentSound.mp3'
import {LargePlayer} from "./largePlayer/largePlayer";
import arrow from './assets/arrow.svg'
import {ButtonContainer} from "./buttonContainer/buttonContainer";
import {useIsMobile} from "../../hooks/useIsMobile";

type Props = {
    mp3Url: string,
    imageUrl: string,
    episodeTitle: string,
    podcastName: string,
    timestampInSecondsToPlayFrom?: number
    playOffsetInSeconds: number
    forceLoading: boolean
    onLargePlayerOpen?: () => void
    onLargePlayerClose?: () => void
}

export const Player = ({
                           playOffsetInSeconds,
                           timestampInSecondsToPlayFrom,
                           mp3Url,
                           imageUrl,
                           episodeTitle,
                           podcastName,
                           forceLoading,
                           onLargePlayerClose,
                           onLargePlayerOpen
                       }: Props) => {

    const audioRef = useRef<HTMLAudioElement>(null);
    const [timeElapsed, setTimeElapsed] = useState(0);
    const [timeRemaining, setTimeRemaining]: any = useState(null);
    const [isPlay, setIsPlay] = useState(false);
    const [duration, setDuration] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isShowLargePlayer, setIsShowLargePlayer] = useState(false);

    const isMobile = useIsMobile()


    useEffect(() => {
        setTimeout(() => {
            if (audioRef.current?.duration) {
                setDuration(audioRef.current.duration)
            }
        }, 5)
    }, [audioRef.current?.onloadedmetadata, mp3Url, isPlay, isLoading])

    useEffect(() => {
        setIsPlay(false);
        setIsLoading(true);
    }, [forceLoading])


    useEffect(() => {
        if (timestampInSecondsToPlayFrom && audioRef.current) {
            audioRef.current.currentTime = getTimeAdjustedWithOffset(timestampInSecondsToPlayFrom, playOffsetInSeconds);
            audioRef.current.autoplay = true
            const src = audioRef.current.src
            const time = audioRef.current.currentTime
            // fix for iPhone
            audioRef.current.src = tempSilentSound
            audioRef.current.src = src
            audioRef.current.currentTime = time
            setIsPlay(true);
            setIsLoading(true);
        }
    }, [timestampInSecondsToPlayFrom, audioRef, playOffsetInSeconds])

    useEffect(() => {
        if (timeRemaining) {
            setIsLoading(false)
        }
    }, [timeRemaining])

    useEffect(() => {
        setIsLoading(false)
    }, [])

    useEffect(() => {
        if (audioRef.current) {
            if (isLoading) {
                audioRef.current.pause()
                return
            }
            if (isPlay) {
                audioRef.current.play()
            }
        }
    }, [isLoading])

    useEffect(() => {
        if (audioRef.current) {
            if (isPlay) {
                audioRef.current.play();
                setIsLoading(true);
                updateTimeElapsedAndRemaining(audioRef.current.currentTime);
                const id = setInterval(() => {
                    if (audioRef.current) {
                        updateTimeElapsedAndRemaining(audioRef.current.currentTime);
                    }
                }, 50);
                return () => {
                    clearInterval(id);
                };
            }
            audioRef.current.pause();
        }

    }, [isPlay])

    useEffect(() => {
        setIsPlay(false);
    }, [])

    const getTimeAdjustedWithOffset = (timeToPlayFrom: number, offset: number) => {
        if (timeToPlayFrom - offset < 0) {
            return 0
        }
        return timeToPlayFrom - offset
    }


    const updateTimeElapsedAndRemaining = (time: number) => {
        setTimeElapsed(time);
        if (audioRef.current) {
            setTimeRemaining(audioRef.current.duration - time)
        }
    }

    const backwardClick = () => {
        if (audioRef.current) {
            audioRef.current.currentTime = (audioRef.current.currentTime - 15);
            updateTimeElapsedAndRemaining(audioRef.current.currentTime);
        }
    }

    const forwardClick = () => {
        if (audioRef.current) {
            audioRef.current.currentTime = (audioRef.current.currentTime + 15);
            updateTimeElapsedAndRemaining(audioRef.current.currentTime);
        }
    }

    const timeBarClick = (time: number) => {
        if (audioRef.current) {
            audioRef.current.currentTime = time;
            updateTimeElapsedAndRemaining(time);
        }
    }

    const volumeBarClick = (volume: number) => {
        if (audioRef.current) {
            audioRef.current.volume = volume;
        }
    }

    const onPlayerClick = () => {
        if (isMobile && onLargePlayerOpen) {
            setIsShowLargePlayer(true)
            const body = document.getElementById("body")
            if (body) {
                body.style.overflow = "hidden"
            }
            onLargePlayerOpen()
        }
    }

    const onCloseButtonClick = () => {
        if (onLargePlayerClose) {
            setIsShowLargePlayer(false)
            const body = document.getElementById("body")
            if (body) {
                body.style.overflow = "auto"
            }
            onLargePlayerClose()
        }
    }


    // todo maybe refactor the player css and html components?
    return <div>
        <div id="player" onClick={onPlayerClick} className={styles.player}>

            <audio src={mp3Url} ref={audioRef}/>

            <div className={styles.container}>

                <div className={styles.upperContainer}>

                    <EpisodeDataContainer imageUrl={imageUrl} episodeTitle={episodeTitle} podcastName={podcastName}/>

                    <div className={styles.playButtonContainer}><ButtonContainer isLoading={isLoading}
                                                                                 backwardClick={backwardClick}
                                                                                 forwardClick={forwardClick}
                                                                                 isPlay={isPlay}
                                                                                 setIsPlay={setIsPlay}
                                                                                 showOnlyPlayerButton={isMobile}/></div>

                    {/*<div className={styles.arrowContainer}>*/}
                    {/*    <img src={arrow} alt="" className={styles.arrow}/>*/}
                    {/*</div>*/}

                    <VolumeContainer setVolume={volumeBarClick}/>

                </div>

                <TimeContainer isLoading={isLoading} changeTime={timeBarClick} timeElapsed={timeElapsed}
                               timeRemaining={timeRemaining}
                               duration={duration}/>

            </div>


        </div>

        <div>
            {isShowLargePlayer &&
            <LargePlayer onClose={onCloseButtonClick} changeTime={timeBarClick}
                         duration={duration} timeElapsed={timeElapsed}
                         timeRemaining={timeRemaining} isLoading={isLoading} backwardClick={backwardClick}
                         forwardClick={forwardClick} setIsPlay={setIsPlay}
                         isPlay={isPlay} imageUrl={imageUrl} episodeTitle={episodeTitle} podcastName={podcastName}/>}
        </div>
    </div>

}