import styles from './cookiePolicy.module.css'
import {Header} from "../../shared/components/header/header";
import fontStyles from "../../shared/fonts/fonts.module.css";
import {Footer} from "../../shared/components/footer/footer";
import {useIsMobile} from "../../shared/hooks/useIsMobile";
import React from "react";


export const CookiePolicy = () => {
    const isMobile = useIsMobile()

    return <div>
        {isMobile ? <Header showSearchButton/> : <Header/>}

        <div className={styles.container}>
            <div className={`${fontStyles.h1} ${styles.mainTitle}`}>
                Cookie Policy
            </div>
            <div className={styles.textContainer}>
                <div className={styles.text}>
                    Effective Date: 18-Jan-2023 <br/>
                    Last Updated: 18-Jan-2023
                </div>

            </div>

            <div className={`${fontStyles.h3} ${styles.title}`}>
                What are cookies?
            </div>
            <div className={styles.textContainer}>
                <div className={styles.text}>
                    This Cookie Policy explains what cookies are and how we use them, the types
                    of cookies we use i.e, the information we collect using cookies and how that information is used,
                    and how to
                    manage the cookie settings.</div> <div className={styles.text}>Cookies are small text files that are used to store small pieces
                of
                information. They are stored on your device when the website is loaded on your browser. These cookies
                help
                us make the website function properly, make it more secure, provide better user experience, and
                understand
                how the website performs and to analyze what works and where it needs improvement.
            </div>
            </div>

            <div className={`${fontStyles.h3} ${styles.title}`}>
                How do we use cookies?
            </div>

            <div className={styles.textContainer}>
                <div className={styles.text}>
                    As most of the online services, our website uses first-party and third-party
                    cookies for several purposes. First-party cookies are mostly necessary for the website to function
                    the right
                    way, and they do not collect any of your personally identifiable data.</div>
                <div className={styles.text}>The third-party
                    cookies used
                    on our website are mainly for understanding how the website performs, how you interact with our
                    website,
                    keeping our services secure, providing advertisements that are relevant to you, and all in all
                    providing
                    you
                    with a better and improved user experience and help speed up your future interactions with our
                    website.
                </div>

            </div>


            <div className={`${fontStyles.h3} ${styles.title}`}>
                Types of Cookies we use
            </div>

            <div className={styles.ckyBannerElement}/>

            <div className={`${fontStyles.h3} ${styles.title}`}>
                Manage cookie preferences
            </div>

            <div className={styles.cookieSettingsContainer}>
                <a className={`${styles.cookieSettingsButton} cky-banner-element`}>Change Your Cookie Settings</a>
            </div>

            <div className={styles.textContainer}>
                <div className={styles.text}>You can change your cookie preferences any time by clicking the above button. This will let you
                    revisit
                    the cookie consent banner and change your preferences or withdraw your consent right away. </div>
                <div className={styles.text}>In
                    addition to this, different browsers provide different methods to block and delete cookies used by
                    websites.
                    You can change the settings of your browser to block/delete the cookies. Listed below are the links
                    to the
                    support documents on how to manage and delete cookies from the major web browsers.</div> <div className={styles.text}>Chrome: <a className={styles.anchorTag}
                href="https://support.google.com/accounts/answer/32050"
                target="_blank">https://support.google.com/accounts/answer/32050</a></div><div className={styles.text}>Safari: <a className={styles.anchorTag}
                href="https://support.apple.com/en-in/guide/safari/sfri11471/mac"
                target="_blank">https://support.apple.com/en-in/guide/safari/sfri11471/mac</a></div><div className={styles.text}>Firefox: <a className={styles.anchorTag}
                href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?redirectslug=delete-cookies-remove-info-websites-stored&redirectlocale=en-US"
                target="_blank">https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?redirectslug=delete-cookies-remove-info-websites-stored&redirectlocale=en-US</a>
            </div><div className={styles.text}>Internet Explorer: <a className={styles.anchorTag}
                href="https://support.microsoft.com/en-us/topic/how-to-delete-cookie-files-in-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc"
                target="_blank">https://support.microsoft.com/en-us/topic/how-to-delete-cookie-files-in-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc</a>
            </div><div className={styles.text}>If you are using any other web browser, please visit your browser’s official support documents.</div>

            </div>

        </div>
        <Footer/>
    </div>


}