import React from 'react';
import ReactDOM from 'react-dom/client';
import {RouterProvider} from "react-router-dom";
import {Router} from "./router";
import './shared/style/index.css'
import {matomoInstance} from "./matomo";
import { MatomoProvider } from '@jonkoops/matomo-tracker-react';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
    <MatomoProvider value={matomoInstance}>
      <RouterProvider router={Router} />
    </MatomoProvider>
);


