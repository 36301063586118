import styles from './imageContainer.module.css'
import {useEffect, useRef} from "react";

type Props = {
    imageUrl: string
    reversed: boolean
}

export const ImageContainer = ({imageUrl, reversed}: Props) => {

    const imageContainerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (reversed && imageContainerRef.current) {
            imageContainerRef.current.classList.add(styles.imageContainerReversed)
        }
    }, [reversed])


    return <div ref={imageContainerRef} className={styles.imageContainer}>
        <img className={styles.image} src={imageUrl} alt=""/>
    </div>

}