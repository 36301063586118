import {SearchContainer} from "../../shared/components/searchContainer/searchContainer";
import {TitleSection} from "./titleSection/titleSection";
import {Header} from "../../shared/components/header/header";
import {PodcastsSection} from "./podcastsSection/podcastsSection";
import {Footer} from "../../shared/components/footer/footer";
import styles from './home.module.css'
import React, {useEffect, useState} from "react";
import {getPodcasts} from "../../shared/api/podcasts/podcasts";
import {useIsMobile} from "../../shared/hooks/useIsMobile";
import {Background} from "../../shared/components/background/background";
import {InfoContainer} from "../../shared/components/infoContainer/infoContainer";
import {Collaborations} from "../../shared/components/collaborations/collaborations";
import {TopSearches} from "../../shared/components/topSearches/topSearches";
import soundImage from './assets/sound.png'
import pearchImage from './assets/pearch.png'
import podcastsImage from './assets/podcasts.png'
import {HelloFreshSquare} from "../../shared/components/ads/helloFreshSquare/helloFreshSquare";
import {HelloFreshBanner} from "../../shared/components/ads/helloFreshBanner/helloFreshBanner";
import {SearchButton} from "./searchButton/searchButton";
import {SearchButtonV2} from "./searchButtonV2/searchButton";


export const Home = () => {
    const [podcastImageUrls, setPodcastImageUrls] = useState<string[]>()
    const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
    const isMobile = useIsMobile()


    useEffect(() => {
        getPodcasts()
            .then((podcasts) => {
                const imageUrls = podcasts.map(p => p.appleImageUrl)
                setPodcastImageUrls(imageUrls)
            })
    }, [])


    return <div>
        {isMobile ? <Header onPopupClose={() => setIsPopupOpen(false)} onPopupOpen={() => setIsPopupOpen(true)}
                            showSearchButton/> :
            <Header/>}

        {!isPopupOpen && <div>
            <div className={styles.container}>
                <TitleSection/>
                {!isMobile && <div className={styles.searchContainer}>
                    <SearchContainer/>
                </div>}

                {isMobile &&
                <div className={styles.searchButtonContainer}><SearchButtonV2/></div>}

                <PodcastsSection urls={podcastImageUrls ? podcastImageUrls : []}/>
            </div>

            <div className={styles.infoContainer}>
                <InfoContainer title={"På gång! Bevaka nyckelord!"}
                               body="Vill du få en notis så fort din favoritaktie nämns? Eller är du kanske intresserad av vad folk tycker om din nya favoritserie? Inom kort erbjuder Pearch möjlighet att bevaka nyckelord. Genom att skapa en bevakning får du en notis så fort ditt nyckelord nämnts bland Sveriges poddar! "
                               imageUrl={soundImage}
                />


            </div>

            {/*{isMobile ? <div className={styles.helloFreshSquareContainer}>*/}
            {/*        <HelloFreshSquare/>*/}
            {/*    </div> :*/}
            {/*    <div className={styles.helloFreshBannerContainer}>*/}
            {/*        <HelloFreshBanner/>*/}
            {/*    </div>*/}
            {/*}*/}


            <div className={styles.topSearchesContainer}>
                <TopSearches/>
            </div>

            <div className={styles.bottomInfoContainer}>

                <InfoContainer
                    reversed
                    title="Upptäck poddar genom ljudsök"
                    body="I vilket avsnitt var det nu det där aktietipset nämndes? Vilka podcasts pratar om mode just nu? Finns det någon ny spännande fotbollspodd? Pearch är till för dig som vill hitta tillbaka, eller upptäcka nya podcastavsnitt! Börja ljudsök idag! "
                    imageUrl={podcastsImage}/>

            </div>

            <div className={styles.collaborationsContainer}>
                <Collaborations/>
            </div>


            <div className={`${styles.infoContainerLast} ${styles.bottomInfoContainer}`}>
                <InfoContainer
                    title="Vad är Pearch?"
                    body="Pearch är en sökmotor för podcasts och erbjuder ljudsökning i Sveriges samtliga största podcasts! Pearch söker igenom allt som nämnts i samtliga avsnitt och ger resultat över matchade avsnitt, samt tidpunkt i avsnitten. Pearch använder AI-teknik för att hitta vad som faktiskt nämnts i podcasten! Testa genom att söka på något som din favorit-podd har pratat om."
                    imageUrl={pearchImage}/>
            </div>

            <Footer/>
            <Background/>
        </div>}

    </div>
}
