import styles from './footer.module.css'
import fontStyles from '../../fonts/fonts.module.css';

export const Footer = () => {

    const generateRandomSearch = () => {
        const randomWords = ["Sverige", "Podcast", "Börsen", "Artificiell", "Intelligens", "Fotboll", "Cannes"]
        return randomWords[Math.floor((Math.random() * (randomWords.length - 1)))]
    }

    return <div className={styles.footer}>
        <div className={styles.container}>

            <div className={`${styles.copyright} ${fontStyles.bodySmall}`}>
                © 2023 Pearch
            </div>

            <a href="/about" className={`${styles.navItem} ${fontStyles.bodySmall}`}>
                Om oss
            </a>
            <a href="/blog" className={`${styles.navItem} ${fontStyles.bodySmall}`}>
                Blogg
            </a>

            <a href="/privacy" className={`${styles.navItem} ${fontStyles.bodySmall}`}>
                Integritetspolicy
            </a>

            <a href="/cookies" className={`${styles.navItem} ${fontStyles.bodySmall}`}>
                Cookies
            </a>

        </div>

    </div>

}