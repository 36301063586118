

const dataLayer = window.dataLayer || []


export const logQuery = (queryString: string) => {
    const data = {"event": "query", "queryString": queryString}
    dataLayer.push(data)
}

export const logPodcastFilter = (podcastName: string, podcastId: string) => {
    const data = {"event": "podcastFilter", "podcastName": podcastName, "podcastId": podcastId}
    dataLayer.push(data)
}




