import styles from './podcastsContainer.module.css'
import {PodcastRow} from "./podcastRow/podcastRow";
import {Spacer} from "../../../../shared/components/common/spacer/spacer";

type Props = {
    numRows: number
    numColumns: number,
    urls: string[]
}

export const PodcastsContainer = ({numRows, numColumns, urls}: Props) => {

    const width = 400;
    urls = urls.map(url => {
        return url.trim() + `${width}x${width}w.webp`
    })

    return <div className={styles.container}>

        {[...Array(numRows).keys()].map((row) => {
            return <div key={row}>
                <PodcastRow numColumns={numColumns} urls={urls.slice(row*numColumns, row*numColumns+numColumns)}/>
            </div>
        })}

    </div>

}