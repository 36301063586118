import styles from "./filterButton.module.css"
import arrow from './assets/arrow.svg'
import orangeArrow from './assets/orangeArrow.svg'
import React, {useEffect, useRef, useState} from "react";

type Props = {
    filterPopButtonPressed: () => void
    isActive: boolean
}

export const FilterButton = ({filterPopButtonPressed, isActive}: Props) => {

    const filterButtonRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        const f = filterButtonRef.current
        if (f) {
            const f = filterButtonRef.current;
            if (isActive) {
                f.style.setProperty("--currentColor", getComputedStyle(f).getPropertyValue('--activeColor'))
                return
            }
            f.style.setProperty("--currentColor", getComputedStyle(f).getPropertyValue('--nonActiveColor'))
        }
    }, [isActive])

    return <button id="filter-podcast-button" ref={filterButtonRef} onClick={filterPopButtonPressed} className={`${styles.filterButton}`}>
        <div className={`${styles.filterText}`}>
            Välj podd
        </div>
        <div className={styles.arrowContainer}>
            {isActive ? <img className={styles.arrow} src={orangeArrow} alt=""/> :
                <img className={styles.arrow} src={arrow} alt=""/>}


        </div>
    </button>

}