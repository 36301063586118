import styles from './podcastElement.module.css'

type props = {
    imageUrl: string
}

export const PodcastElement = ({imageUrl}: props) => {

    return <div className={styles.container}>
        <img className={styles.image} alt="" src={imageUrl}/>
    </div>
}