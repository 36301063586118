import {Header} from "../../shared/components/header/header";
import {Footer} from "../../shared/components/footer/footer";
import fontStyles from '../../shared/fonts/fonts.module.css'
import styles from './about.module.css'
import innovationLogo from './innovationLogo.png'
import {useIsMobile} from "../../shared/hooks/useIsMobile";
import React from "react";

export const About = () => {

    const isMobile = useIsMobile()


    return <div>
        {isMobile ? <Header showSearchButton/> : <Header/>}
        <div className={styles.container}>
            <div className={`${fontStyles.h1} ${styles.mainTitle}`}>
                Om oss
            </div>
            <div className={`${fontStyles.h3} ${styles.title}`}>
                Vad är pearch?
            </div>
            <div className={styles.textContainer}>
                <p className={styles.text}>
                    Pearch är en sökmotor för podcasts och erbjuder ljudsökning i Sveriges samtliga största
                    podcasts!
                </p>
                <p className={styles.text}>
                    Pearch söker igenom allt som nämnts i samtliga avsnitt
                    och ger resultat över matchade avsnitt, samt tidpunkt i avsnitten.
                    Pearch använder AI-teknik för att hitta vad som faktiskt nämnts i podcasten!
                </p>
            </div>

            <div className={`${fontStyles.h3} ${styles.title}`}>
                Vem är pearch till för?
            </div>
            <div className={styles.textContainer}>
                <p className={styles.text}>
                    I vilket avsnitt var det nu som X sade Y? Vilka podcasts pratar om Z?
                </p>
                <p className={styles.text}>
                    Pearch är till för dig som vill hitta tillbaka, eller upptäcka nya podcastavsnitt!
                </p>
            </div>


            <div className={`${fontStyles.h3} ${styles.title}`}>
                Vilka podcasts har ni?
            </div>
            <div className={styles.textContainer}>
                <p className={styles.text}>
                    I dagsläget erbjuder Pearch möjligheten att söka i Sveriges största podcasts. Vi lägger ständigt till nya podcasts och jobbat hårt på att inkludera podcasts från fler länder inom kort.
                </p>
            </div>

            <div className={`${fontStyles.h3} ${styles.title}`}>
                Vad är ljudsök?
            </div>
            <div className={styles.textContainer}>
                <p className={styles.text}>
                    Ljudsök är precis som det låter: möjlighet att kunna söka i talat ljud. Vi på Pearch tyckte det var svårt att hitta i talat ljud, speciellt i podcasts. Vi bestämde oss för att försöka göra något åt det!
                </p>
            </div>

            <div className={`${fontStyles.h3} ${styles.title}`}>
                Kontakt
            </div>

            <div className={styles.textContainer}>
                <p className={styles.text}>
                    Tveka inte på att kontakta pearch om du har frågor eller synpukter.
                </p>
                <p className={styles.text}>
                    Du når oss enklast på <span> <a target="_blank" href="mailto:hello@pearch.se">hello@pearch.se</a></span>
                </p>

                <p className={styles.companyText}>
                    aFrequency Technology AB
                </p>
                <p className={styles.companyText}>
                    Org nr: 559375-4426
                </p>

                <img className={styles.innovationLogo} src={innovationLogo} alt=""/>
            </div>

            <div className={`${fontStyles.h3} ${styles.title}`}>
                Ansvarsfriskrivning
            </div>

            <div className={styles.textContainer}>
                <p className={styles.text}>
                    Pearch äger inte upphovsrätten till några av resultaten som visas i sökmotorn.
                    Avsnitten, bilder och övrigt som tillhör respektive podcasts ägs av respektive podcasts upphovsrättsägare. Inte av eller med pearch.
                </p>
                <p className={styles.text}>
                    Pearch ansvarar inte för resultaten och/eller dess korrekthet. Pearch förbehåller sig rätten för eventuella fel.
                </p>
            </div>


        </div>
        <Footer/>
    </div>

}