import styles from './podcastContainer.module.css'
import fontStyles from '../../../../shared/fonts/fonts.module.css'
import {PodcastFilter} from "../../../api/filters/filters.type";

type Props = {
    podcast: PodcastFilter
    onClick: (id: string, name: string) => void
}

export const PodcastContainer = ({podcast, onClick}: Props) => {

    return <div onClick={() => onClick(podcast.id, podcast.name)} className={styles.fullContainer}>
        <div className={styles.container}>

            <div className={styles.imageContainer}>
                <img className={styles.image} alt="" src={podcast.appleImageUrl + "150x150w.webp"}/>
            </div>

            <div className={styles.rightContainer}>
                <div className={styles.upperContainer}>
                    <div className={styles.title}>
                        {podcast.name}
                    </div>

                </div>

                <div className={`${styles.lowerContainer} ${fontStyles.textButton}`}>
                    <div className={styles.metadata}>
                        Podcast
                    </div>

                </div>
            </div>
        </div>
    </div>

}