import styles from './privacyPolicy.module.css'
import {Header} from "../../shared/components/header/header";
import {Spacer} from "../../shared/components/common/spacer/spacer";
import fontStyles from "../../shared/fonts/fonts.module.css";
import {Footer} from "../../shared/components/footer/footer";
import {useIsMobile} from "../../shared/hooks/useIsMobile";
import React from "react";


export const PrivacyPolicy = () => {

    const isMobile = useIsMobile()

    return <div>
        {isMobile ? <Header showSearchButton/> : <Header/>}

        <div className={styles.container}>
            <div className={`${fontStyles.h1} ${styles.mainTitle}`}>
                Privacy Policy
            </div>

            <div className={styles.textContainer}>
                <div className={styles.text}>
                    Last Updated On 18-Jan-2023 <br/>
                    Effective Date 18-Jan-2023
                </div>
            </div>

            <div className={styles.textContainer}>


                <div className={styles.text}>
                    This Privacy Policy describes the policies of AFrequency Technology AB, Kungsholms Strand 143,
                    Stockholm 11248, Sweden, email: bjorn@afrequency.se, phone: +46739046594 on the collection, use and
                    disclosure of your information that we collect when you use our website (
                    https://www.pearch.se ). (the “Service”). By accessing or using the Service,
                    you are consenting to the collection, use and disclosure of your information in accordance with this
                    Privacy Policy. If you do not consent to the same, please do not access or use the Service.
                </div>

                <div className={styles.text}>
                    We may modify this Privacy Policy at any time without any prior notice to you and will post the
                    revised Privacy Policy on the Service. The revised Policy will be effective 180 days from when the
                    revised Policy is posted in the Service and your continued access or use of the Service after such
                    time will constitute your acceptance of the revised Privacy Policy. We therefore recommend that you
                    periodically review this page.
                </div>
            </div>


            <div className={`${fontStyles.h3} ${styles.title}`}>
                HOW WE USE YOUR INFORMATION:
            </div>
            <div className={styles.textContainer}>
                <div className={styles.text}>
                    We will use the information that we collect about you for the following purposes:<br/><br/>

                    - Marketing/ Promotional<br/>
                    - Customer feedback collection<br/>
                    - Administration info<br/>
                    - Targeted advertising<br/><br/>
                    If we want to use your information for any other purpose, we will ask you for consent and will use
                    your information only on receiving your consent and then, only for the purpose(s) for which grant
                    consent unless we are required to do otherwise by law.
                </div>
            </div>


            <div className={`${fontStyles.h3} ${styles.title}`}>
                HOW WE SHARE YOUR INFORMATION:
            </div>

            <div className={styles.textContainer}>
                <div className={styles.text}>
                    We will not transfer your personal information to any third party without seeking your consent,
                    except in limited circumstances as described below:<br/><br/>

                    - Ad service<br/>
                    - Analytics<br/>
                    - Data collection & process<br/>
                    We require such third party’s to use the personal information we transfer to them only for the
                    purpose for which it was transferred and not to retain it for longer than is required for fulfilling
                    the said purpose.

                </div>
                <div className={styles.text}>
                    We may also disclose your personal information for the following: (1) to comply with applicable law,
                    regulation, court order or other legal process; (2) to enforce your agreements with us, including
                    this Privacy Policy; or (3) to respond to claims that your use of the Service violates any
                    third-party rights. If the Service or our company is merged or acquired with another company, your
                    information will be one of the assets that is transferred to the new owner.
                </div>

            </div>


            <div className={`${fontStyles.h3} ${styles.title}`}>
                YOUR RIGHTS:
            </div>

            <div className={styles.textContainer}>
                <div className={styles.text}>
                    Depending on the law that applies, you may have a right to access and rectify or erase your personal
                    data or receive a copy of your personal data, restrict or object to the active processing of your
                    data, ask us to share (port) your personal information to another entity, withdraw any consent you
                    provided to us to process your data, a right to lodge a complaint with a statutory authority and
                    such other rights as may be relevant under applicable laws. To exercise these rights, you can write
                    to us at hello@pearch.se. We will respond to your request in accordance with applicable law.
                </div>
                <div className={styles.text}>
                    Do note that if you do not allow us to collect or process the required personal information or
                    withdraw the consent to process the same for the required purposes, you may not be able to access or
                    use the services for which your information was sought.
                </div>

            </div>


            <div className={`${fontStyles.h3} ${styles.title}`}>
                COOKIES ETC.
            </div>

            <div className={styles.textContainer}>
                <div className={styles.text}>
                    To learn more about how we use these and your choices in relation to these tracking technologies,
                    please
                    refer to our <a style={{fontWeight: "bold", cursor: "pointer"}} href="/cookies">Cookie Policy. </a>
                </div>
            </div>

            <div className={`${fontStyles.h3} ${styles.title}`}>
                SECURITY:
            </div>

            <div className={styles.textContainer}>
            <div className={styles.text}>
                The security of your information is important to us and we will use reasonable security measures to
                prevent the loss, misuse or unauthorized alteration of your information under our control. However,
                given the inherent risks, we cannot guarantee absolute security and consequently, we cannot ensure or
                warrant the security of any information you transmit to us and you do so at your own risk.
            </div>
            </div>

            <div className={`${fontStyles.h3} ${styles.title}`}>
                THIRD PARTY LINKS & USE OF YOUR INFORMATION:
            </div>

            <div className={styles.textContainer}>
            <div className={styles.text}>
                Our Service may contain links to other websites that are not operated by us. This Privacy Policy does
                not address the privacy policy and other practices of any third parties, including any third party
                operating any website or service that may be accessible via a link on the Service. We strongly advise
                you to review the privacy policy of every site you visit. We have no control over and assume no
                responsibility for the content, privacy policies or practices of any third party sites or services.
            </div>
            </div>

            <div className={`${fontStyles.h3} ${styles.title}`}>
                GRIEVANCE / DATA PROTECTION OFFICER:
            </div>

            <div className={styles.textContainer}>
            <div className={styles.text}>
                If you have any queries or concerns about the processing of your information that is available with us,
                you may email our Grievance Officer at aFrequency Technology AB, Kungsholms Strand 143, email:
                hello@pearch.se. We will address your concerns in accordance with applicable law.
            </div>
            </div>

        </div>
        <Footer/>
    </div>

}