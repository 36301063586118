import styles from './sortFilterContainer.module.css'
import React, {useEffect, useState} from "react";
import {SortOrder} from "../../../../shared/enum/sort/sort.enum";
import {Filter} from "../../../../shared/enum/filter/filter.enum";
import {useSearchParams} from "react-router-dom";
import {UrlParam} from "../../../../shared/enum/urlParam/urlParam.enum";
import {SortButton} from "./sortButton/sortButton";
import {FilterButton} from "./filterButton/filterButton";

export const SortFilterContainer = () => {

    const sortValues = [
        {value: SortOrder.RELEVANCE, name: "Relevans"},
        {value: SortOrder.LATEST, name: "Senast"},
        {value: SortOrder.NAME_ASCENDING, name: "Namn a-ö"}]

    const filterValues = [
        {value: Filter.NO_FILTER, name: "När som helst"},
        {value: Filter.LAST_WEEK, name: "Senaste veckan"},
        {value: Filter.LAST_MONTH, name: "Senaste månaden"},
        {value: Filter.LAST_YEAR, name: "Senaste året"},]

    const [searchParams, setSearchParams] = useSearchParams();
    const [currentSortValue, setCurrentSortValue]: any = useState(sortValues[0]);
    const [currentTimeFilterValue, setCurrentTimeFilterValue]: any = useState(filterValues[0]);


    const onSortValueClick = (sortValue: string) => {
        searchParams.set(UrlParam.SORT_BY, sortValue)
        setSearchParams(searchParams);
    }

    const onFilterValueClick = (filterValue: string) => {
        if (filterValue == Filter.NO_FILTER) {
            searchParams.delete(UrlParam.FILTER_DATE)
            setSearchParams(searchParams)
            return;
        }
        searchParams.set(UrlParam.FILTER_DATE, filterValue)
        setSearchParams(searchParams);
    }

    useEffect(() => {
        const searchParamSortOrder = searchParams.get(UrlParam.SORT_BY)
        const searchParamsTimeFilter = searchParams.get(UrlParam.FILTER_DATE)

        if (searchParamSortOrder) {
            sortValues.forEach((sortVal) => {
                if (searchParamSortOrder == sortVal.value) {
                    setCurrentSortValue(sortVal)
                    return
                }
            })
        }

        if (searchParamsTimeFilter) {

            filterValues.forEach((filterVal) => {
                if (searchParamsTimeFilter == filterVal.value) {
                    setCurrentTimeFilterValue(filterVal)
                    return
                }
            })
        } else {
            setCurrentTimeFilterValue(filterValues[0])
        }



    }, [searchParams])




    return <div className={styles.container}>

        <SortButton onSortValueClick={onSortValueClick} values={sortValues} currentValue={currentSortValue}/>
        <FilterButton onFilterValueClick={onFilterValueClick} values={filterValues} currentValue={currentTimeFilterValue}/>

    </div>

}