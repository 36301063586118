import styles from './searchResults.module.css'
import fontStyles from '../../../shared/fonts/fonts.module.css'
import {Episode} from "./episode/episode";
import {EpisodeSearchResult as EpisodeType} from '../../../shared/api/query/query.types'
import {Spacer} from "../../../shared/components/common/spacer/spacer";
import {useIsMobile} from "../../../shared/hooks/useIsMobile";
import React, {useState} from "react";
import {SortFilterContainer} from "./sortFilterContainer/sortFilterContainer";
import {BookBeatBanner} from "../../../shared/components/ads/bookBeatBanner/bookBeatBanner";
import {HelloFreshBanner} from "../../../shared/components/ads/helloFreshBanner/helloFreshBanner";
import {NextoryBanner} from "../../../shared/components/ads/nextoryBanner/nextoryBanner";

type Props = {
    results: EpisodeType[]
    playEpisode: (timestampInSeconds: number, episode: EpisodeType) => void
}

export const SearchResults = ({results, playEpisode}: Props) => {
    const [currentHitPlaying, setCurrentHitPlaying] = useState<{ timestamp: number, episodeId: string }>({
        timestamp: 0,
        episodeId: ""
    })

    const changeCurrentHitPlaying = (timestamp: number, episodeId: string) => {
        setCurrentHitPlaying({timestamp: timestamp, episodeId: episodeId})
    }
    return <div className={styles.container}>

        {results.map((result, index) => {
            return <div style={{width: "100%"}} key={index}>
                <Episode tokens={result.matchedWords} currentHitPlaying={currentHitPlaying}
                         setCurrentHitPlaying={changeCurrentHitPlaying} playEpisode={playEpisode} episode={result}/>

                {/*{index % 9 == 0 && index != 0 && index % 2 == 1 && <div className={styles.inResultsBannerContainer}>*/}
                {/*    <div className={styles.nextoryBanner}>*/}
                {/*        <NextoryBanner/>*/}
                {/*    </div>*/}

                {/*</div>}*/}
                {/*{index % 9 == 0 && index != 0 && index % 2 == 0 &&*/}
                {/*<div className={styles.inResultsBannerContainer}>*/}
                {/*    <div className={styles.helloFreshBanner}>*/}
                {/*        <HelloFreshBanner/>*/}
                {/*    </div>*/}
                {/*</div>}*/}

            </div>
        })}

    </div>

}
