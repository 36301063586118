
export enum SortOrder {
    LATEST = "latest",
    OLDEST = "oldest",
    RELEVANCE = "relevance",
    LEAST_HITS = "leastHits",
    NAME_DESCENDING = "nameDescending",
    NAME_ASCENDING = "nameAscending",
    PODCAST = "podcast"
}
