import {PodcastElement} from "./podcastElement/podcastElement";
import styles from './podcastRow.module.css'

type Props = {
    numColumns: number
    urls: string[]
}

export const PodcastRow = ({numColumns, urls}: Props) => {

    return <>
        {urls.length === numColumns ?

            <div className={styles.container}>
                {urls.map((url, column) => {
                    return <PodcastElement key={column} imageUrl={url}/>
                })}  </div>

            : <div className={styles.unfilledContainer}>
                {urls.map((url, column) => {
                    return <div className={styles.podcastElementContainer}>
                        <PodcastElement key={column} imageUrl={url}/>
                    </div>
                })}  </div>
        }
    </>
}