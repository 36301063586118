import fontStyles from "../../../../shared/fonts/fonts.module.css"
import styles from './podcastSectionTitle.module.css'

export const PodcastSectionTitle = () => {

    return <div className={`${styles.container}`}>
        <div className={`${fontStyles.h3} ${styles.textContainer}`}>
            Ett urval av tillgängliga podcasts
        </div>
    </div>
}