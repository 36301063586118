import styles from './activeFiltersContainer.module.css'
import closeButton from './assets/closeButton.svg';
import fontStyles from '../../../fonts/mobileFonts.module.css'
import {useSearchParams} from "react-router-dom";
import {UrlParam} from "../../../enum/urlParam/urlParam.enum";


type Props = {
    value: string
}

export const ActiveFiltersContainer = ({value}: Props) => {

    const [searchParams, setSearchParams] = useSearchParams();

    const removeFilters = () => {
        searchParams.delete(UrlParam.FILTER_PODCAST_IDS);
        setSearchParams(searchParams);
    }

    return <button onClick={removeFilters} className={styles.container}>
        <div className={styles.closeContainer}>
            <img src={closeButton} className={styles.closeImage} alt="close"/>
        </div>
        <div className={`${styles.titleContainer}`}>
            {value}
        </div>
    </button>

}