import styles from "./searchContainer.module.css";
import React, {useEffect, useRef, useState} from "react";
import {SearchBar} from "./searchBar/searchBar";
import {ActiveFiltersContainer} from "../header/activeFiltersContainer/activeFiltersContainer";
import {PodcastFilter} from "../../api/filters/filters.type";
import {getFilters} from "../../api/filters/filters";
import {UrlParam} from "../../enum/urlParam/urlParam.enum";
import {createSearchParams, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {FilterPopup} from "../filterPopup/filterPopup";
import {SearchPopup} from "../searchPopup/searchPopup";
import {useIsMobile} from "../../hooks/useIsMobile";
import {SortOrder} from "../../enum/sort/sort.enum";
import {logQuery} from "../../analytics/analytics";

type Props = {
    onPopupOpen?: () => void
    onPopupClose?: () => void
    headerDividerRef?: any
    showSearchBar?: boolean
}


export const SearchContainer = ({onPopupOpen, onPopupClose, headerDividerRef, showSearchBar = true}: Props) => {

    const searchBarRef = useRef<HTMLDivElement>(null)
    const [filteredValues, setFilteredValues] = useState<PodcastFilter[]>([])
    const [searchParams, _] = useSearchParams();

    const [podcastFilters, setPodcastFilters] = useState<PodcastFilter[]>([])
    const [showFilterPopup, setShowFilterPopup] = useState<boolean>(false);
    const [showSearchPopup, setShowSearchPopup] = useState<boolean>(false);

    useEffect(() => {
        getFilters()
            .then((data) => {
                setPodcastFilters(data.podcasts)
            })
    }, [])

    useEffect(() => {
        filterPodcasts()
    }, [podcastFilters, searchParams])

    const filterPodcasts = () => {
        const filterValues: string | null = searchParams.get(UrlParam.FILTER_PODCAST_IDS)
        const filteredPodcasts: PodcastFilter[] = []
        if (filterValues && podcastFilters) {
            const parsedFilterValues: string[] = JSON.parse(filterValues)
            podcastFilters.forEach((podcast) => {
                parsedFilterValues.forEach((filteredPodcastId) => {
                    if (filteredPodcastId == podcast.id) {
                        filteredPodcasts.push(podcast)
                    }
                })
            })
            setFilteredValues(filteredPodcasts)
            return
        }
        setFilteredValues([])
    }

    const isMobile = useIsMobile()

    useEffect(() => {
        adjustHeaderDivider()
    }, [filteredValues])

    const adjustHeaderDivider = () => {
        if (headerDividerRef) {
            if (headerDividerRef?.current)
                if (filteredValues.length > 0) {
                    headerDividerRef.current.style.top = "136px";
                } else {
                    headerDividerRef.current.style.top = "106px";
                }
        }
    }

    const navigate = useNavigate()
    const location = useLocation()
    const [currentSearchParams, setSearchParams] = useSearchParams();


    const initiateQuery = (query: string) => {
        logQuery(query);
        let filterValues = currentSearchParams.get(UrlParam.FILTER_PODCAST_IDS)
        let searchParams: any = {q: query, sort: SortOrder.RELEVANCE}
        if (filterValues) {
            searchParams[`${UrlParam.FILTER_PODCAST_IDS}`] = filterValues
        }

        if (location.pathname === "/search") {
            setSearchParams(searchParams)
            return
        }
        navigate({pathname: "search", search: createSearchParams(searchParams).toString()})
    }

    const handlePopupSearch = (query: string) => {
        initiateQuery(query)
        setShowSearchPopup(false)
    }

    const handleShowSearchPop = () => {
        if (isMobile) {
            setShowSearchPopup(true)
            if (onPopupOpen) {
                onPopupOpen()
            }
        }
    }

    const filterPopupBackButtonPressed = () => {
        window.scrollTo({top: 0})
        setShowFilterPopup(false)
        if (onPopupClose) {
            onPopupClose()
        }
    }

    const searchPopupBackButtonPressed = () => {
        window.scrollTo({top: 0})
        setShowSearchPopup(false)
        if (onPopupClose) {
            onPopupClose()
        }
    }

    const filterPopButtonPressed = () => {
        setShowFilterPopup(!showFilterPopup)
        if (onPopupOpen) {
            onPopupOpen()
        }
    }

    return <div className={styles.container}>

        {showSearchBar && <SearchBar currentRef={searchBarRef}
                                     isFiltered={filteredValues.length > 0}
                                     filterPopButtonPressed={filterPopButtonPressed}
                                     handleShowSearchPop={handleShowSearchPop} initiateQuery={initiateQuery}
                                     podcastFilters={podcastFilters}
                                     filterPopupBackButtonPressed={filterPopupBackButtonPressed}
                                     showFilterPopup={showFilterPopup}/>}

        {showSearchBar && filteredValues.map((podcast, index) => {
            return <div className={styles.activeFiltersContainer} key={index}>
                <ActiveFiltersContainer value={podcast.name}/>
            </div>
        })}


        {showSearchPopup &&
        <SearchPopup onSearch={handlePopupSearch} backButtonPressed={searchPopupBackButtonPressed}/>}
        <div id="openSearchBar" onClick={handleShowSearchPop} className={styles.invisible}/>
    </div>
}
