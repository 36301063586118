import fontStyles from "../../../shared/fonts/fonts.module.css";
import styles from "./titleSection.module.css"
import {Spacer} from "../../../shared/components/common/spacer/spacer";

export const TitleSection = () => {

    return <div className={styles.container}>
        <div className={`${fontStyles.h1} ${styles.title}`}>
            <span className={styles.span}>Sök</span> i Sveriges största <span className={styles.span}>podcasts</span>
        </div>

        <div className={`${fontStyles.subTitle} ${styles.subTitle}`}>
            Pearch AI hittar podcast-avsnitt utifrån citat, namn eller ämnen!
        </div>
    </div>

}