import styles from './background.module.css'
import rectangle from './assets/rectangle.svg'


export const Background = () => {

    return <div className={styles.container}>
        <div className={`${styles.background}`}>
            <img src={rectangle} className={styles.desktopBackgroundImage}/>
        </div>
        <div className={`${styles.background}`}>
            <img src={rectangle} className={styles.desktopBackgroundImage}/>
        </div>
        <div className={`${styles.background}`}>
            <img src={rectangle} className={styles.desktopBackgroundImage}/>
        </div>
    </div>

}