import styles from './playingAnimation.module.css'


export const PlayingAnimation = () => {
    return <div className={styles.body}>
        <div className={styles.boxContainer}>
            <div className={`${styles.box} ${styles.box1}`}/>
            <div className={`${styles.box} ${styles.box2}`}/>
            <div className={`${styles.box} ${styles.box3}`}/>
            <div className={`${styles.box} ${styles.box4}`}/>
            <div className={`${styles.box} ${styles.box5}`}/>
        </div>
    </div>

}