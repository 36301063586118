import styles from './popup.module.css'
import {Filter} from "../../../../../shared/enum/filter/filter.enum";
import {SortOrder} from "../../../../../shared/enum/sort/sort.enum";
import {useEffect, useRef} from "react";

type Props = {
    width?: number,
    values: { value: string, name: string }[]
    onValueClick: (pressedVal: string) => void
    currentValue: Filter.LAST_MONTH | Filter.LAST_WEEK | Filter.LAST_YEAR | Filter.NO_FILTER | SortOrder.LATEST | SortOrder.NAME_ASCENDING | SortOrder.RELEVANCE
}

export const Popup = ({values, onValueClick, currentValue, width}: Props) => {

    const containerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (width && containerRef.current) {
            containerRef.current.style.width = width+"px";
        }
    }, )

    return <div ref={containerRef} className={styles.container}>

        <div className={styles.valuesContainer}>
            {values.map((value) => {
                if (value.value == currentValue) {
                    return <div className={`${styles.valueContainer} ${styles.activeValueContainer}`} key={value.value}>
                        <div className={styles.circleContainer}>
                            <div className={styles.circle}/>
                        </div>
                        <div className={`${styles.value} ${styles.activeValue}`}>
                            {value.name}
                        </div>
                    </div>
                }
                return <div onClick={() => onValueClick(value.value)} className={styles.valueContainer} key={value.value}>
                    <div  className={styles.value}>
                        {value.name}
                    </div>
                </div>
            })}
        </div>
    </div>

}