import styles from './blog.module.css'
import {BlogPost} from "./blogPost/blogPost";
import {Header} from "../../shared/components/header/header";
import React from "react";
import {useIsMobile} from "../../shared/hooks/useIsMobile";
import fontStyles from "../../shared/fonts/fonts.module.css";
import {Footer} from "../../shared/components/footer/footer";
import podcastImage from './assets/podcastPhoto.jpg'
import pearchImage from './assets/pearchImage.svg'
import trends from './assets/trends.png'


export const Blog = () => {

    const isMobile = useIsMobile();

    return <div>
        {isMobile ? <Header showSearchButton/> : <Header/>}


        <div className={styles.container}>

            <div className={`${fontStyles.h1} ${styles.mainTitle}`}>
                Blogg
            </div>

            <BlogPost imageUrl={podcastImage} title="Några Poddtips"
                      body="Poddar finns det gott om i det svenska podcast-landskapet! Vi på Pearch har några favoriter som vi gärna vill dela med oss av. I det här inlägget finns de poddar som vi inte kan klara oss utan och även ett urplock av några favoritsegment! "
                      sections={[{subTitle: "Alex & Sigges Podcast ", body: "Alex Schulman och Sigge Eklunds podd som släpps varje fredag förgyller alltid helgen med ett gott skratt och finurliga vardagsfunderingar! Inte konstigt att det är en av Sveriges största poddar!"},
                          {subTitle: "", body: "Ett favoritsegment är självklart historien om Musk-parfymen. Även snigelskämtet är en klar favorit! ", buttons: [{title: "Se avsnitten om Musk", href: "/search?q=Musk&sort=mostHits&podcastIds=%5B\"00ab4772\"%5D"}, {title: "Se avsnitten om snigel-skämtet", href: "/search?q=snigel&sort=mostHits&podcastIds=%5B\"00ab4772\"%5D"}]},
                          {subTitle: "Flashback Forever", body: "Ytterligare en favorit är podden Flashback Forever där Emma Knyckare, Ina Lundström och Scroll-Mia diskuterar aktuella trådar på online-forumet Flashback. Otroligt rolig podd utan dess like! "},
                          {subTitle: "", body: "En favorit är segmentet om Flashback-tråden som hypotetiskt diskuterar ett kioskrån!", buttons: [{title: "Se avsnitt om rånet", href: "/search?q=r%C3%A5n&sort=mostHits&podcastIds=%5B%223532eab7%22%5D"}]},
                          {subTitle: "Börspodden", body: "John Skogman och Johan Isaksson driver nog den i särklass mest underhållande podden om börsen och aktier. Ett måste om man är intresserad av börsen och samtidigt vill ha ett gott skratt! "},
                          {subTitle: "", body: "De flertalet segment om gratispengar är alltid intressant! Vi på Pearch uppskattar också varmt Johns hejdå i varje avsnitt! ", buttons: [{title: "Se avsnitten om gratispengar", href: "/search?q=gratis&sort=mostHits&podcastIds=%5B\"52858467\"%5D"}, {title: "Se avsnitten om hejdå-segmenten", href: "/search?q=hejdå&sort=mostHits&podcastIds=%5B\"52858467\"%5D"}, {title: "Hitta i Börspodden", href: "/search/borspodden?q=Börspodden&sort=mostHits&podcastIds=%5B\"52858467\"%5D"}]},
                          {subTitle: "Sommar & Vinter i P1", body: "Sommar & Vinter i P1 behöver ingen närmre introduktion. Perfekt att slå på ett härligt sommarprat en varm sommardag vid stranden medan man slappar i solstolen! "},
                          {subTitle: "", body: "Avsnittet av Max Tegmark skulle vi vilja lyfta fram. Särskilt det segmentet om Artificiell Intelligens som, om något, är högt aktuellt idag!", buttons: [{title: "Se avsnitten om Artificiell Intelligens", href: "/search?q=intelligens&sort=mostHits&podcastIds=%5B%22b8aed09a%22%5D"}]},
                          {subTitle: "Avslutningsvis", body: "Pearch erbjuder såklart fler poddar än ovan nämnda. Samtliga populära svenska podcasts finns tillgängliga för ljudsök! "},]}
            />

            <div className={styles.blogPostMarginDivider}/>

            <BlogPost imageUrl={trends} title="Senaste statistik och trender" body="Nedan har vi samlat lite statistik och trender om hur Sveriges 30 mest populära podcasts pratat om populära samtalsämnen. Nyckelorden som vi valt att kika på är som följer:"
                      sections={[{subTitle: "Inflation", body: "I dessa tider är det inte anmärkningsvärt att ordet inflation ligger högt upp. Inflation nämnes under november månad fyra gånger, under december månad 24 gånger och under januari 22 gånger."},
                          {subTitle: "Ränta", body: "På samma tema har ordet ränta nämnts i 16 podcastavsnitt under januari månad. Det är en ökning med hela 100% från december månads 8 omnämningar."},
                          {subTitle: "El", body: "Med den rådande elkrisen är el ett högaktuellt nyckelord. Under januari månad nämndes el 8 gånger, vilket är en minskning från december månad med 15 omnämningar."},
                          {subTitle: "AI (Artificiell Intelligens)", body: "AI-chattbotten ChatGPT har nog inte undgått någon! Under januari månad har ordet AI nämnts 9 gånger! December månad var antalet omnämningar för AI 5 och under november månad enbart en gång."},
                          {subTitle: "Sport", body: "Med både fotbolls- och handboll-VM bakom oss kikar vi på nyckelordet Sport. Med 17 omnämningar i november, 23 under december och 10 under januari. Ett populärt samtalsämne i podcast-Sverige med andra ord!  "},
                          {subTitle: "Zlatan", body: "Vi alla gillar ju Zlatan! Zlatan har dykt upp som ett populärt nyckelord på senaste tiden. Från två omnämningar under november, 14 under december och 11 under januari. "}]}
            />


            <div className={styles.blogPostMarginDivider}/>

            <BlogPost imageUrl={pearchImage} title="Intro till Pearch"
                      body="Kul att du har hittat hit! I detta korta inlägg kommer vi gå igenom vad Pearch är och hur du kan använda tjänsten för att hitta i talat ljud!"
            sections={[{subTitle: "Ljudmediet", body: "Vi på Pearch vill underlätta för dig som vill hitta i det talade ljudmediet! Vårt mål är att göra ljudmediet så tillgängligt som möjligt."},
                {subTitle: "Podcasts", body: "I dagsläget erbjuder Pearch ljudsök i samtliga populära svenska podcasts! Vi utökar ständigt utbudet med målet att bli heltäckande. Har du en specifik podcast som inte finns med? Hör av dig! "},
                {subTitle: "Ljudsök", body: "Ljudsök innebär precis vad det låter som, att det är möjligt att söka i det faktiska ljudet. Undrar du om din nämner en kändis du tycker om? Vill du hitta tillbaka till det där roliga skämtet som du inte längre hittar? Kanske vill du bara hitta en ny podcast som nämner räntan? Pearch är perfekt för dessa ändamål!"},
                {subTitle: "Hur gör jag?", body: "Börja med att sök på något citat, ämne eller ord som du är intresserad av för att se hur Pearch fungerar!"}]}
                      bottomButton={{title: "Testa ljudsök med Pearch", href: "/"}}
            />


        </div>

        <Footer/>
    </div>


}