import styles from './searchResultsInfoNoHits.module.css'
import fontStyles from '../../../shared/fonts/fonts.module.css'
import {Spacer} from "../../../shared/components/common/spacer/spacer";
import {SortFilterContainer} from "../searchResults/sortFilterContainer/sortFilterContainer";

type Props = {
    query: string,
    numHits: number
    noHits?: boolean
}

export const SearchResultsInfoNoHits = ({query, numHits, noHits}: Props) => {

    return <div className={styles.container}>


        <div className={`${styles.title} ${fontStyles.h3}`}>
            {noHits ? <div className={`${styles.title} ${fontStyles.h3}`}> <span className={`${styles.query} ${fontStyles.h3}`}> {query}</span> gav inga träffar</div> :
                <div className={`${styles.title} ${fontStyles.h3}`}> Du sökte på <span className={`${styles.query} ${fontStyles.h3}`}> {query}</span></div>}

        </div>

        <div className={`${styles.body} ${fontStyles.bodySmall}`}>
            Hittade du inte det du sökte? Testa ett annat sökord eller filtrera resultaten.
        </div>

    </div>

}