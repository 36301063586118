import styles from './textContainer.module.css'
import {useEffect, useRef} from "react";

type Props = {
    title: string
    body: string
    reversed: boolean
}

export const TextContainer = ({title, body, reversed}: Props) => {

    const textContainerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (reversed && textContainerRef.current) {
            textContainerRef.current.classList.add(styles.textContainerReversed)
        }
    }, [reversed])


    return <div ref={textContainerRef} className={styles.textContainer}>
        <div className={styles.titleContainer}>
            {title}
        </div>

        <div className={styles.bodyContainer}>
            {body}
        </div>
    </div>

}