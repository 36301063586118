import {createBrowserRouter} from "react-router-dom";
import {Home} from "./pages/home/home";
import {Search} from "./pages/search/search";
import {UrlParam} from "./shared/enum/urlParam/urlParam.enum";
import {About} from "./pages/about/about";
import {CookiePolicy} from "./pages/cookiePolicy/cookiePolicy";
import {PrivacyPolicy} from "./pages/privacyPolicy/privacyPolicy";
import {Blog} from "./pages/blog/blog";
import {RedirectToHome} from "./shared/components/redirectToHome/redirectToHome";

export const Router = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
        errorElement: <div>error</div>,
    },
    {
        path: "/search",
        element: <Search/>,
        errorElement: <div>error</div>,
        loader: function loader({ request } : {request: any}) {
            const url = new URL(request.url);
            const query = url.searchParams.get(UrlParam.QUERY_STRING)
            if (query) {
                document.title = `${query} - pearch`;
            }
            return url.searchParams.get(UrlParam.QUERY_STRING);
        },
    },
    {
        path: "/about",
        element: <About/>,
        errorElement: <div>error</div>,
    },
    {
        path: "/cookies",
        element: <CookiePolicy/>,
        errorElement: <div>error</div>,
    },
    {
        path: "/privacy",
        element: <PrivacyPolicy/>,
        errorElement: <div>error</div>,
    },
    {
        path: "/blog",
        element: <Blog/>,
        errorElement: <div>error</div>,
    },
    {
        path: "/search/borspodden",
        element: <Search/>,
        errorElement: <div>error</div>,
        loader: function loader({ request } : {request: any}) {
            const url = new URL(request.url);
            const query = url.searchParams.get(UrlParam.QUERY_STRING)
            if (query) {
                document.title = `${query} | Hitta i avsnitten här | pearch`;
            }
            return url.searchParams.get(UrlParam.QUERY_STRING);
        },
    },
    {
        path: "*",
        element: <RedirectToHome/>,
        errorElement: <div>error</div>,
    },
]);