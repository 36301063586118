import axios from "axios";
import {MonthFilter, QueryFilters, QueryFiltersResponse, YearFilter} from "./filters.type";
import {Endpoint} from "../endpoint.enum";

const monthsDict: string[] = [
    "Januari",
    "Februari",
    "Mars",
    "April",
    "Maj",
    "Juni",
    "Juli",
    "Augusti",
    "September",
    "Oktober",
    "November",
    "December"
]


export const getFilters = async (): Promise<QueryFilters> => {
    const url = Endpoint.BASE_URL + Endpoint.FILTERS
    return axios.get(url)
        .then((response) => {
            const data: QueryFiltersResponse = response.data
            const years: YearFilter[] = []
            const months: MonthFilter[] = []
            const parsedOldestEpisodeDate = parseDate(data.oldestEpisodeDate)

            const currentYear: number = new Date().getFullYear()
            const minYear = parsedOldestEpisodeDate.getFullYear()

            for (let y = currentYear; y >= minYear; y--) {
                years.push({id: y, name: `${y}`});
            }

            for (let m = 0; m < 12; m++) {
                months.push({id: m+1, name: monthsDict[m]});
            }
            return {podcasts: data.podcasts, years: years, months: months}

            }
        )
        .catch((e) => {
            return e.response
        })

}

const parseDate = (d: string): Date => {
    const year = d.substring(0,4);
    const month = d.substring(5, 7);
    const day = d.substring(8, 10);

    return new Date(parseInt(year), parseInt(month)-1, parseInt(day))
}
