import {BackendResponse, EpisodeSearchResult} from "./query.types";
import {mockResult} from "./mockResult";
import {Endpoint} from "../endpoint.enum";
import axios from "axios";
import qs from "qs";

export const query = async (queryString: string, sortOrder: string, podcastFilterIds: string[], date: string, yearsToFilterOn: number[], monthsToFilterOn: number[], offset: number=0): Promise<BackendResponse> => {
    const url = Endpoint.BASE_URL + Endpoint.QUERY

    const options = {
        params: {
            query: queryString,
            sortOrder: sortOrder,
            podcasts: podcastFilterIds,
            date: date,
            offset: offset
        },
        paramsSerializer: {
            serialize: (params: any) => qs.stringify(params, { arrayFormat: "repeat" })
        }
    }


    return axios.get(url, options)
        .then((response) => {
                return response.data as BackendResponse;
            }
        )
        .catch((e) => {
            // console.log(e.response)
            return e.response
        })
}
