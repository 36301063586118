import styles from "./episodeDataContainer.module.css"
import fontStyles from "../../../../shared/fonts/fonts.module.css";

type Props = {
    imageUrl: string,
    episodeTitle: string,
    podcastName: string
}

export const EpisodeDataContainer = ({imageUrl, episodeTitle, podcastName}: Props) => {

    return <div className={styles.episodeDataContainer}>

        <img className={styles.episodeImage} src={imageUrl} alt=""/>

        <div className={styles.episodeTexts}>
            <div className={`${styles.episodeTitle} ${fontStyles.textButton}`}>
                {episodeTitle}
            </div>

            <div className={styles.podcastName}>
                {podcastName}
            </div>
        </div>

    </div>
}