import styles from './hit.module.css'
import {fromSecondsToHoursMinutesSeconds} from "../../../../../shared/utils/utils";
import {useEffect, useState} from "react";
import {PlayingAnimation} from "./playingAnimation/playingAnimation";
import playArrow from './assets/playArrow.svg'

type Props = {
    tokens: string[],
    timestampInSeconds: number,
    onPlay: (timestamp: number) => void
    episodeId: string
    currentHitPlaying: { timestamp: number, episodeId: string }
    setCurrentHitPlaying: (timestamp: number, episodeId: string) => void
}

export const Hit = ({tokens, timestampInSeconds, onPlay, episodeId, currentHitPlaying, setCurrentHitPlaying}: Props) => {

    const [isPlaying, setIsPlaying] = useState<boolean>(false)
    const [tokenText, setTokenText] = useState<string>("")

    const playPressed = () => {
        setCurrentHitPlaying(timestampInSeconds, episodeId);
        onPlay(timestampInSeconds);
    }

    useEffect(() => {
        let tempTokenText = ""
        tokens.forEach((token) => {
            tempTokenText += " " + token
        })
        setTokenText(tempTokenText)
    }, [tokens])

    useEffect(() => {
        if (currentHitPlaying.timestamp === timestampInSeconds && currentHitPlaying.episodeId == episodeId) {
            setIsPlaying(true);
        } else {
            setIsPlaying(false)
        }
    }, [currentHitPlaying])

    return <div className={styles.container}>

        <div className={styles.leftContainer}>
            {isPlaying ? <PlayingAnimation/> :
                <button id="play-in-hit" onClick={playPressed} className={`${styles.playButton}`}>
                    <div className={styles.imageArrowContainer}>
                        <img src={playArrow} alt=""/>
                    </div>
                    <div className={styles.timestampContainer}>
                        <div className={styles.timestamp}>{fromSecondsToHoursMinutesSeconds(timestampInSeconds)}</div>
                    </div>

                </button>}
        </div>

        <div className={styles.rightContainer}>
            <div className={styles.timestampAndQuery}>
                <span className={styles.queryText}> {tokenText}</span> nämns
                vid {fromSecondsToHoursMinutesSeconds(timestampInSeconds)}
            </div>

        </div>
    </div>

}