import {PodcastSectionTitle} from "./podcastSectionTitle/podcastSectionTitle";
import styles from "./podcastsSection.module.css"
import {PodcastsContainer} from "./podcastsContainer/podcastsContainer";
import {Spacer} from "../../../shared/components/common/spacer/spacer";
import {useIsMobile} from "../../../shared/hooks/useIsMobile";
import {useEffect, useState} from "react";

type Props = {
    urls: string[]
}

export const PodcastsSection = ({urls}: Props) => {
    const [numRows, setNumRows] = useState<number>(4);
    const [numColumns, setNumColumns] = useState<number>(5);

    const isMobile = useIsMobile();

    useEffect(() => {
        if (isMobile) {
            setNumColumns(3);
            setNumRows(3);
        }
    }, [isMobile])

    return <div className={styles.container}>
        <PodcastSectionTitle/>

        <PodcastsContainer urls={urls} numRows={numRows} numColumns={numColumns}/>

    </div>
}