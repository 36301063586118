import styles from './popupSearchBar.module.css'
import arrowBack from './assets/backArrow.svg'
import closeImage from './assets/closeButton.svg'
import React, {useEffect, useRef, useState} from "react";
import searchIcon from "./assets/searchIcon.svg"

type Props = {
    placeholderValue: string
    backButtonPressed: () => void
    onEnterPress?: (value: string) => void
    onChange?: (value: string) => void
    enterKeyHint?: "search" | "enter" | "done" | "go" | "next" | "previous" | "send" | undefined
    preFilledValue?: string | null
}

export const PopupSearchBar = ({
                                   placeholderValue,
                                   backButtonPressed,
                                   onEnterPress,
                                   onChange,
                                   enterKeyHint = "search",
                                   preFilledValue
                               }: Props) => {

    const inputRef = useRef<HTMLInputElement>(null)
    const [showClearButton, setShowClearButton] = useState<boolean>(false)


    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
        }
    }, [])


    const onInputChange = (target: HTMLInputElement) => {
        if (onChange) {
            onChange(target.value)
        }

        if (target.value.length > 0) {
            setShowClearButton(true)
            return
        }
        setShowClearButton(false)
    }

    const clearButton = () => {
        if (inputRef.current) {
            inputRef.current.value = ""

            setShowClearButton(false)
            inputRef.current?.focus()
        }
    }

    const handleSubmit = () => {
        if (inputRef.current) {
            inputRef.current.blur()
            if (onEnterPress) {
                onEnterPress(inputRef.current.value)
                backButtonPressed()
            }
        }
    }

    useEffect(() => {
        if (inputRef.current && preFilledValue) {
            inputRef.current.value = preFilledValue;
            setShowClearButton(true);
        }
    }, [preFilledValue])

    return <div className={styles.container}>

        <div className={styles.backButtonContainer}>
            <button onClick={backButtonPressed} className={styles.backButton}>
                <img className={styles.image} src={arrowBack} alt="backArrow"/>
            </button>

        </div>

        <div className={styles.searchIconContainer}>
            <img src={searchIcon} className={styles.searchImage} alt=""/>
        </div>


        <div className={styles.inputContainer}>
            <input onSubmit={handleSubmit} onKeyPress={(event) => {
                if (event.key === "Enter" && event.currentTarget.value != "") {
                    handleSubmit()
                }
            }}
                   enterKeyHint={enterKeyHint} onChange={(e) => onInputChange(e.currentTarget)}
                   ref={inputRef}
                   // onFocus={() => {
                   //     setTimeout(() => {
                   //         window.scrollBy({top: -20});
                   //     }, 100)
                   // }
                   // }
                   className={`${styles.input} `}
                   placeholder={placeholderValue}/>
        </div>

        <div className={styles.closeButtonContainer}>

            {showClearButton && <button onClick={clearButton} className={styles.closeButton}>
                <img className={styles.image} src={closeImage} alt="close"/>
            </button>}

        </div>


    </div>

}