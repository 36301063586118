import styles from './podcast.module.css'

type Props = {
    place: number
    imageUrl: string
    title: string,
    productionCompany: string
}

export const Podcast = ({place, imageUrl, title, productionCompany}: Props) => {

    return <div className={styles.container}>

        <div className={styles.imageContainer}>
            <img className={styles.image} src={imageUrl} alt={""}/>
        </div>

        <div className={styles.titleContainer}>
            {place+"."} {title}
        </div>

        <div className={styles.productionCompanyContainer}>
            {productionCompany}
        </div>

    </div>

}