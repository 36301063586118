import styles from './filterPopup.module.css'
import {PopupSearchBar} from "../popupSearchBar/popupSearchBar";
import {PodcastContainer} from "./podcastContainer/podcastContainer";
import {PodcastFilter} from "../../api/filters/filters.type";
import {useState} from "react";
import {UrlParam} from "../../enum/urlParam/urlParam.enum";
import {useSearchParams} from "react-router-dom";
import {logPodcastFilter} from "../../analytics/analytics";

type Props = {
    onClose: () => void
    podcastFilters: PodcastFilter[]
}

export const FilterPopup = ({onClose, podcastFilters}: Props) => {

    const sortPodcasts = (podcasts: PodcastFilter[]) => {
        return podcasts.sort((a, b) => {
            return a.name.replace(/[^A-Za-z]/g, "").localeCompare(b.name.replace(/[^A-Za-z]/g, ""))
        })
    }

    const [searchParams, setSearchParams] = useSearchParams();
    const [podcastsToShow, setPodcastsToShow] = useState<PodcastFilter[]>(sortPodcasts(podcastFilters));

    const filterPodcasts = (filterValue: string) => {
        return podcastFilters.filter((podcast) => {
            if (podcast.name.toLowerCase().includes(filterValue.toLowerCase())) {
                return podcast;
            }
        }) as PodcastFilter[];
    }


    const filterOnChange = (searchValue: string) => {
        setPodcastsToShow(sortPodcasts(filterPodcasts(searchValue)));
    }

    const onSearchBarEnterPress = () => {
        if (podcastsToShow.length === 1) {
            setFilterOnPodcast(podcastsToShow[0].id, podcastsToShow[0].name)
        }
    }

    const setFilterOnPodcast = (podcastId: string, podcastName: string) => {
        logPodcastFilter(podcastName, podcastId)
        searchParams.set(UrlParam.FILTER_PODCAST_IDS, JSON.stringify([podcastId]));
        setSearchParams(searchParams);
        onClose();
    }

    return <div className={styles.container}>

        <PopupSearchBar enterKeyHint="go" onEnterPress={onSearchBarEnterPress} onChange={filterOnChange}
                        placeholderValue="Filtrera på podcast" backButtonPressed={onClose}/>

        <div className={styles.podcastsContainer}>
            {podcastsToShow.map((podcast) => {
                return <div style={{width: "100%"}} key={podcast.id}>
                    <PodcastContainer onClick={setFilterOnPodcast} podcast={podcast}/>
                </div>
            })}
        </div>


    </div>

}