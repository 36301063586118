import styles from "./buttonContainer.module.css";
import backwardButton from "./assets/backward.svg";
import pauseButton from "./assets/pause.svg";
import playButton from "./assets/play.svg";
import forwardButton from "./assets/forward.svg";
import React, {useEffect, useRef} from "react";
import {LoaderAnimation} from "../../loaderAnimation/loaderAnimation";

export type Props = {
    backwardClick: () => void,
    forwardClick: () => void,
    setIsPlay: (isPlay: boolean) => void,
    isPlay: boolean
    isLoading: boolean
    isInLargePlayer?: boolean
    showOnlyPlayerButton?: boolean
}

export const ButtonContainer = ({
                                    isPlay,
                                    backwardClick,
                                    forwardClick,
                                    setIsPlay,
                                    isLoading,
                                    isInLargePlayer = false,
                                    showOnlyPlayerButton = false
                                }: Props) => {

    const buttonContainerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (buttonContainerRef.current) {
            if (isInLargePlayer) {
                buttonContainerRef.current.style.position = "sticky"
                buttonContainerRef.current.style.margin = "auto"
                buttonContainerRef.current.style.marginTop = "0"
                buttonContainerRef.current.style.marginBottom = "0"
                buttonContainerRef.current.style.setProperty('--buttonWidth', 32 + "px")
                buttonContainerRef.current.style.width = "180px"
            }

            if (showOnlyPlayerButton) {
                buttonContainerRef.current.style.position = "absolute"
                buttonContainerRef.current.style.right = "20%"
                buttonContainerRef.current.style.setProperty('--buttonWidth', 24 + "px")
                buttonContainerRef.current.style.marginTop = "5px"
            }

        }

    }, [isInLargePlayer, showOnlyPlayerButton])

    // useEffect(() => {
    //     window.addEventListener("keydown", (event) => {
    //         if (event.key == " ") {
    //             if (!isLoading) {
    //                 setIsPlay(!isPlay)
    //                 event.preventDefault()
    //             }
    //         }
    //     })
    // },)

    return <div ref={buttonContainerRef} className={styles.buttonContainer}>

        {!showOnlyPlayerButton && <img id="backward-button" onClick={(event) => {
            if (!isLoading) {
                backwardClick()
                event.stopPropagation()
            }
        }} className={styles.button} alt="" src={backwardButton}/>}


        <div id="play-pause-button" onClick={(event) => {
            if (!isLoading) {
                setIsPlay(!isPlay)
                event.stopPropagation()
            }

        }}>
            {isLoading ? <div style={{transform: "scale(0.6)"}}><LoaderAnimation/></div> : isPlay ?
                <img className={styles.button} alt="" src={pauseButton}/> :
                <img className={styles.button} alt="" src={playButton}/>}
        </div>

        {!showOnlyPlayerButton && <img id="forward-button" onClick={(event) => {
            if (!isLoading) {
                forwardClick()
                event.stopPropagation()
            }
        }} className={styles.button} alt="" src={forwardButton}/>}

    </div>

}